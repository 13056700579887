import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUrl } from "../../../common/feedbackApi";
import { apiUrls } from "../../../common/apiUrls";
import moment from "moment";
import { convertToCamelCase } from "../../../utils/utilities";

const INTIAL_STATE = {
  customerReviews: {},
  loading: false,
  error: null,
};

export const fetchCustomerReview = createAsyncThunk(
  "reviews/customerReview",
  async (payload, thunkAPI) => {
    let reviewFromDate = payload.fromDate;
    let reviewToDate = payload.toDate;
    const response = await getUrl(
      apiUrls.getAndFilterReviews(reviewFromDate, reviewToDate)
    );
    if (!response.success) {
      throw new Error("Unable to fetch reviews at the moment!");
    } else {
      return convertToCamelCase(response.data);
    }
  }
);

export const customerReviewSlice = createSlice({
  name: "customerReviews",
  initialState: INTIAL_STATE,
  reducers: {
    addReviews: (state, action) => {
      state.customerReviews = action.payload;
    },
    clearReviews: (state, action) => {
      state.customerReviews = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerReview.fulfilled, (state, action) => {
        (state.customerReviews = action.payload), (state.loading = false);
      })
      .addCase(fetchCustomerReview.rejected, (state, action) => {
        (state.customerReviews = []),
          (state.error = "Unable to fetch review at the moment!");
        state.loading = false;
      })
      .addCase(fetchCustomerReview.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.customerReviews = [];
      });
  },
});

export const { addReviews, clearReviews } = customerReviewSlice.actions;
export default customerReviewSlice.reducer;
