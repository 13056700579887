import React, { useEffect, useState } from "react";
import "./index.css";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";

function AppModal({
  isOpen = false,
  title = "",
  onOkay = () => {},
  onCancel = () => {},
  okayText = "Okay",
  cancelText = "Cancel",
  children,
}) {
  const [open, setOpen] = useState(isOpen);


  const handleOnCancel = () => {
    onCancel();
    setOpen(false);
  };

  const handleOnOkay = () => {
    onOkay();
  };

  return (
    <Modal
      open={isOpen}
      footer={() => null}
      onOk={handleOnOkay}
      onCancel={handleOnCancel}
      okText={okayText}
      cancelText={cancelText}
      title={title}
      centered
      maskClosable={false}
      closeIcon={<IoClose size={20} color="black" />}
      transitionName=""
    >
      {children}
    </Modal>
  );
}

export default React.memo(AppModal);
