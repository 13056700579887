import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useRef } from "react";
import { CgClose } from "react-icons/cg";

function Modal({ open = false, children, onOkay, onCancel }) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current && open) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [open]);

  const handleClose = () => {
    const modal = modalRef.current;
    if (modalRef.current) {
      modal.classList.add("d-none");
    }
  };

  const handleOpen = () => {
    const modal = modalRef.current;
    if (modalRef.current) {
      modal.classList.remove("d-none");
    }
  };

  return createPortal(
    <div id="myModal" className="app-modal d-none" ref={modalRef}>
      <div className="app-modal-content col-md-5 col-sm-11">
        <div className="d-flex justify-content-end">
        <CgClose size={20}  />
        </div>
        {children}
      </div>
    </div>,
    document.getElementById("modal-portal")
  );
}

export default Modal;
