// App.js
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoaderProvider } from "./context/LoaderContext";
import ErrorBoundary from "./modules/ErrorBoundary/ErrorBoundary";

const KEY = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_KEY;
import Routes from "./components/main/Routes";
import {
  NotificationPortal,
  Notification,
} from "./components/Notification/Notification.component";
import { Loader, LoaderPortal } from "./components/Loader/Loader.component";
import { Modal, ModalPortal } from "./components/Modal/AppModal.component";

const App = () => {
  return (
    <>
      <NotificationPortal>
        <Notification />
      </NotificationPortal>
      <LoaderPortal>
        <Loader />
      </LoaderPortal>
      <Routes />
    </>
  );
};

const Main = () => {
  return (
    <LoaderProvider>
      {" "}
      {/* Should get rid of this and move to redux */}
      <GoogleOAuthProvider clientId={KEY}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </GoogleOAuthProvider>
    </LoaderProvider>
  );
};

export default Main;
