import { INPUT_TYPES } from "../../../../components/FormComponent/FormConstants";
import { validateEmail, validateMobileNumber, validatePassword } from "../../../../utils/validators";
import { LuEyeOff } from "react-icons/lu";

export const signupModel = [
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "mobile",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Phone Number",
    validators: [validateMobileNumber],
    maxLength: 10,
    showFlag: true
  },
  {
    type: INPUT_TYPES.DROP_DOWN,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "bizType",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Select Business Type",
    maxLength: 10,
    options: [
      { value: "restaurant", name: "Restaurant" },
      { value: "clinic", name: "Clinic" },
      { value: "others", name: "Others" },
    ],
  },
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "bizName",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Business Name",
    maxLength: 50,
  },
];

export const emailPasswordSingup = [
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "email",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Enter your email",
    validators: [validateEmail],
  },
  // {
  //   type: INPUT_TYPES.PASSWORD,
  //   icon: <LuEyeOff size={17} style={{marginRight:"10px", marginLeft: "5px"}} color="#C2C2C2" />,
  //   disabled: false,
  //   value: "",
  //   required: true,
  //   fieldName: "password",
  //   inputStyleClass: "login-input-style-class",
  //   styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
  //   placeholder: "Create a password",
  //   validators: [validatePassword],
  //   onClickIcon: () => {},
  // },
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "bizName",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Business Name",
    maxLength: 50,
  },
  {
    type: INPUT_TYPES.DROP_DOWN,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "bizType",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Business Type",
    maxLength: 10,
    options: [
      { value: "restaurant", name: "Restaurant" },
      { value: "clinic", name: "Clinic" },
      { value: "others", name: "Others" },
    ],
  },
]

export const otpModal = {
  type: INPUT_TYPES.TEXT_INPUT,
  disabled: false,
  value: "",
  required: true,
  fieldName: "otp",
  inputStyleClass: "login-input-style-class",
  styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
  placeholder: "Enter OTP",
  maxLength: 5,
};
