import customerReviewSlice from "./slices/HomePage/customerReviewSlice"
import userSlice from "./slices/User/userSlice"
import notificationSlice from "./slices/Commons/notificationSlice"
import qrCodesSlice from "./slices/Qrcodes/qrCodesSlice"

export const rootReducer = {
    customerReviews: customerReviewSlice,
    user: userSlice,
    notifications: notificationSlice,
    qrCodes: qrCodesSlice
}