import { useEffect } from "react";

export const useInput = ({
  fieldName,
  onChange,
  value,
  maxLength,
  validators,
  required,
}) => {
  const handleOnChangeInput = (e) => {
    let validatedValue = validate(e.target.value);
    onChange(e.target.value, fieldName, validatedValue);
  };

  useEffect(() => {
    validate()
  }, [value])

  const validate = (value) => {
    if (required && !value) {
      return {
        value,
        isValid: false,
      };
    }
    return { value, isValid: true };
  };

  return {
    handleOnChangeInput,
  };
};
