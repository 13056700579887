import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { completeSignup, resendVerificationLink } from "../Signup.actions";
import { useNavigate } from "react-router-dom";
import { identifiers } from "../../../utils/identifiers";
import { routes } from "../../../common/routeUrls";

const actionTypes = {
  resend: "resend",
  login: "login",
};

export const useAutoLogin = () => {
  const [params] = useSearchParams();
  const [messages, setMessages] = useState(
    "Almost there...We are logging you in."
  );
  const [progressStatus, setProgressStatus] = useState({
    percentage: 80,
    status: "active",
  });
  const navigate = useNavigate();
  const [linkExpired, setLinkExpired] = useState(false);
  const [actionButtonType, setActionButtonType] = useState(actionTypes.resend);

  useEffect(() => {
    document.title = "Login | QR Pro Studio";
    handleAutoLogin();
  }, []);

  const handleAutoLogin = async () => {
    const resp = await completeSignup(params?.get("token"));
    console.log(resp);
    setMessages(resp.message);

    if (!resp.success) {
      setProgressStatus((prev) => ({
        ...prev,
        status: "exception",
        percentage: 100,
      }));
      if (resp.status === 409) {
        setActionButtonType(actionTypes.login);
      } else {
        setActionButtonType(actionTypes.resend);
      }
    } else {
      setProgressStatus({ status: "success", percentage: 100 });
      localStorage.setItem(identifiers.is_new_user, true);
      setTimeout(() => {
        return navigate(routes.qrCodes);
      }, 2000);
    }
  };

  const handleResendVerification = async () => {
    console.log(params.get("email"));
    setProgressStatus((prev) => ({
      ...prev,
      status: "active",
      percentage: 80,
    }));
    setMessages("Almost there...We are sending verification link!");
    const resp = await resendVerificationLink(params.get("email"));
    if (resp.success) {
      navigate(routes.signupOkay);
    } else {
      setProgressStatus((prev) => ({
        ...prev,
        status: "exception",
        percentage: 100,
      }));
      setMessages(resp.message);
    }
    if (resp && resp?.emailAlreadyExistsError) {
      setActionButtonType(actionTypes.login);
    }
  };

  return {
    messages,
    progressStatus,
    linkExpired,
    actionButtonType,
    handleResendVerification,
  };
};
