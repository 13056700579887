import { INPUT_TYPES } from "../../../../components/FormComponent/FormConstants";
import {
  validateMobileNumber,
  validateEmail,
} from "../../../../utils/validators";

export const loginModalConstant = [
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "mobile",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Phone Number",
    validators: [validateMobileNumber],
    maxLength: 10,
    showFlag: true,
  },
];

export const basicLoginModalConstant = [
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "email",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Email Address",
    validators: [validateEmail],
    // maxLength: 10,
    // showFlag: true,
  },
  {
    type: INPUT_TYPES.PASSWORD,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "password",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Password",
    // validators: [validateEmail],
    // maxLength: 10,
    // showFlag: true,
  },
];

export const otpModal = {
  type: INPUT_TYPES.TEXT_INPUT,
  disabled: false,
  value: "",
  required: true,
  fieldName: "otp",
  inputStyleClass: "login-input-style-class",
  styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
  placeholder: "Enter OTP",
  maxLength: 5,
};
