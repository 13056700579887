import { useReviewListing } from "./ReviewListing.hook";
import React from "react";
import { Skeleton } from "antd";
import Table from "../../../components/Table/Table";
import ReviewListingMobile from "./ReviewListingMobile.component";
import { CDN_URL } from "../../../common/Constants";

function ReviewListing() {
  const {
    customerReviews,
    reviewColumnsData,
    reviewSourceData,
    handleOnRetry,
  } = useReviewListing();

  if (customerReviews.loading === false && reviewSourceData.length === 0) {
    return (
      <div className="message-container" style={{ marginTop: "1rem" }}>
        <img
          src={`${CDN_URL}/no_results_found.jpeg`}
          className="no-result-found"
          alt="no results found"
        />
        <p>No results found. Trying adding other filters !</p>
        <div className="w-100 row d-flex justify-content-center">
          <button onClick={() => handleOnRetry(1)} className="app-button col-md-2 col-sm-12 mt-3" >
            Refresh
          </button>
          {/* <button
            onClick={() => handleOnRetry(7)}
            className="app-button app-ghost-button col-md-2 col-sm-12"
          >
            Get Last 7 days
          </button> */}
        </div>
      </div>
    );
  }

  return (
    <div style={{ marginTop: "30px" }}>
      <Skeleton
        loading={customerReviews.loading}
        style={{ maxWidth: 1000 }}
      ></Skeleton>
      <Skeleton
        loading={customerReviews.loading}
        style={{ maxWidth: 1000 }}
      ></Skeleton>
      <div className="fullscreen-table-view">
        {customerReviews.loading === false && !customerReviews.error ? (
          <Table
            loading={customerReviews.loading}
            columns={reviewColumnsData}
            dataSource={reviewSourceData}
          />
        ) : (
          <p></p>
        )}
      </div>
      <div className="d-none mobile-review-view">
        {customerReviews.loading === false && !customerReviews.error ? (
          <ReviewListingMobile
            columns={reviewColumnsData}
            dataSource={reviewSourceData}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ReviewListing;
