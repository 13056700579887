import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";

function Button({
  text = "",
  onClick = () => {},
  loading = false,
  disabled = false,
  className = "",
  icon = null,
}) {
  console.log(onClick)
  return (
    <button
      className={classNames([styles["btn-v2"]], className)}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
      {icon}
    </button>
  );
}

export default Button;
