import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import "./index.css";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  closeNotification,
  notifyWithType,
} from "../../main/slices/Commons/notificationSlice";
import { identifiers } from "../../utils/identifiers";
import moment from "moment";
import { saveToLocalStorage } from "../../utils/utilities";

function Notification() {
  const notification = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    let timerId = undefined;
    if(notification.title){
      timerId = setTimeout(() => {
        dispatch(closeNotification());
      }, 10*1000);
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [notification.title])

  return (
    <>
      {notification?.title ? (
        <div
          className={`notification-container ${notification.notify && notification.title ? "enters" : "exits"} ${notification.type}`}
        >
          <>
            {notification.title}
            <AiOutlineClose
              className="notification-close-icon"
              onClick={() => dispatch(closeNotification())}
            />
          </>
        </div>
      ) : null}
    </>
  );
}

const NotificationPortal = ({ children }) => {
  const notificationPortal = document.getElementById("portal-root");
  return createPortal(children, notificationPortal);
};

NotificationPortal.propTypes = {
  children: PropTypes.element.isRequired,
};

export { Notification, NotificationPortal };
