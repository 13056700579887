import React, { useEffect, useState } from "react";
import Search from "../../Search/Search";
import { fetchUser } from "../../../main/slices/User/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { TfiArrowCircleDown, TfiArrowCircleUp } from "react-icons/tfi";
import AccountInfoDropdown from "../../AccountInfoDropdown/AccountInfoDropdown.component";
import MobileHeader from "./MobileHeader.component";
import { NavLink } from "react-router-dom";
import { routes } from "../../../common/routeUrls";

const VERSION_KEY = process.env.REACT_APP_CURRENT_VERSION;

const BellIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0277 0C7.73472 0 5.80843 1.72411 5.55522 4.00306L4.5 13.5H1.5C0.671573 13.5 0 14.1716 0 15V16.5C0 17.3284 0.671573 18 1.5 18H22.5C23.3284 18 24 17.3284 24 16.5V15C24 14.1716 23.3284 13.5 22.5 13.5H19.5L18.4448 4.00306C18.1916 1.72411 16.2653 0 13.9723 0H10.0277Z"
        fill="#4880FF"
      />
      <rect
        opacity="0.3"
        x="9"
        y="19.5"
        width="6"
        height="6"
        rx="2.25"
        fill="#FF0000"
      />
    </svg>
  );
};

function AppHeader({ shrink = false, handleToggle }) {
  const userId = localStorage.getItem("user_id");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser(userId));
  }, []);

  const handleDropdownShow = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleDropdownHide = () => {
    setDropdownOpen((prev) => !prev);
  };

  const user = useSelector((state) => state.user);
  const username = user?.loading
    ? "Anon"
    : user?.user?.organization_details?.[0].org_name ||
      (user?.user?.email ? user?.user?.email.substring(0, 8) : "Anon");
  return (
    <>
      <div className={`header`}>
        <Search />
        <div className="header__account-info">
          <div style={{ marginInline: "2.5rem" }}>
            {VERSION_KEY !== "v1" && <BellIcon />}
          </div>
          <div
            className="d-flex align-items-center account-dropdown-container"
            onMouseEnter={handleDropdownShow}
            onMouseLeave={handleDropdownHide}
          >
            <div className="header__account-conatiner">
              <img
                src="https://robohash.org/103.160.27.159.png"
                alt="profile pciture"
                id="profile-pic"
              ></img>
              <div>
                <p id="username">
                  {user?.loading
                    ? "Anon"
                    : user?.user?.organization_details?.[0].org_name ||
                      (user?.user?.email
                        ? user?.user?.email.substring(0, 8)
                        : "Anon")}
                </p>
                <p id="role">Admin</p>
              </div>
            </div>
            {VERSION_KEY !== "v1" && (
              <>
                {!dropdownOpen ? (
                  <TfiArrowCircleDown color="rgba(86, 86, 86, 1)" size={17} />
                ) : (
                  <TfiArrowCircleUp color="rgba(86, 86, 86, 1)" size={17} />
                )}
                <AccountInfoDropdown />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="sm-header">
        <MobileHeader handleToogle={handleToggle} username={username} />
      </div>
    </>
  );
}

export default React.memo(AppHeader);
