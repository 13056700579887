import React from "react";
import './index.css'

function Loader() {
  return (
    <div className="loading-spinner">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={64}
        height={54}
        viewBox="0 0 64 54"
        fill="none"
      >
        <rect width={64} height={54}/>
        <mask
          id="mask0_1223_2938"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={11}
          y={6}
          width={42}
          height={42}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.7442 47.4885C43.201 47.4885 52.4885 38.201 52.4885 26.7442C52.4885 15.2875 43.201 6 31.7442 6C20.2875 6 11 15.2875 11 26.7442C11 38.201 20.2875 47.4885 31.7442 47.4885ZM31.7442 39.0404C38.5352 39.0404 44.0404 33.5352 44.0404 26.7442C44.0404 19.9532 38.5352 14.4481 31.7442 14.4481C24.9532 14.4481 19.4481 19.9532 19.4481 26.7442C19.4481 33.5352 24.9532 39.0404 31.7442 39.0404Z"
            fill="#CB202D"
          />
        </mask>
        <g mask="url(#mask0_1223_2938)">
          <rect
            x={9}
            y={4}
            width={46}
            height={46}
            fill="url(#paint0_angular_1223_2938)"
          />
          <circle cx={32.0224} cy={43.5202} r={4.26827} fill="#3DBDFF" />
        </g>
        <defs>
          <radialGradient
            id="paint0_angular_1223_2938"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(32 27) rotate(90) scale(23)"
          >
            <stop stopColor="#3DBDFF" />
            <stop offset={1} stopColor="white" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}

export default Loader;
