import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./main/store";
import posthog from "posthog-js";

if (
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY &&
  process.env.NODE_ENV === "production"
) {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: "always",
  });
}

// if (process.env.NODE_ENV !== "production") {
//   posthog.reset(); // Clear any existing tracking preferences
//   posthog.opt_out_capturing(); // Disable tracking
//   console.log("PostHog capturing is disabled for non-production environments");
// } else {
//   posthog.opt_in_capturing(); // Ensure capturing is on for production
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App subject="Clarice" />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
