import React, { useState } from "react";

export const useOnFormChange = () => {
  const [formData, setFormData] = useState({});

  const handleFormChange = (formName, value, fieldName) => {
    setFormData((prev) => {
      return {
        ...prev,
        [formName]: {
          ...prev[formName],
          [fieldName]: value,
        },
      };
    });
  };

  const resetForm = () => {
    setFormData({});
  };

  const updateForm = (formValues) => {
    setFormData({...formValues});
  };

  return {
    formData,
    handleFormChange,
    updateForm,
    resetForm,
  };
};
