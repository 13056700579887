import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomerReview } from "../../../main/slices/HomePage/customerReviewSlice";
import moment from "moment";
import { IoMdStar } from "react-icons/io";
import "./index.css";

export const useReviewListing = () => {
  const [reviewColumnsData, setReviewColumnsData] = useState([]);
  const [reviewSourceData, setReviewSourceData] = useState([]);
  const customerReviews = useSelector((state) => state.customerReviews);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    let { customerReviews: reviewsResponse } = customerReviews;
    if (reviewsResponse?.results && reviewsResponse?.results?.length) {
      prepareColumnsInfo();
      prepareSourceData();
    } else {
      setReviewColumnsData([]);
      setReviewSourceData([]);
    }
  }, [customerReviews]);

  const getRender = (dataSourceValue, colName) => {
    if (colName === "Comment") {
      return <div className="feedback-comments">{dataSourceValue}</div>;
    }
    dataSourceValue = parseInt(dataSourceValue);
    let renderArray = [];
    for (let i = 0; i < 5; i++) {
      if (i < dataSourceValue) {
        renderArray.push(
          <IoMdStar color="orange" size={14} style={{ marginRight: 5 }} />
        );
      } else {
        renderArray.push(<IoMdStar color="#202224" size={14} />);
      }
    }
    let colorIndicator =
      dataSourceValue >= 4 ? "green" : dataSourceValue < 3 ? "red" : "#36383a";
    return (
      <>
        <div>{renderArray}</div>
        <div
          className={`text-small-value`}
          style={{ color: colorIndicator }}
        >{`${dataSourceValue ? dataSourceValue : 0} stars`}</div>
      </>
    );
  };

  const prepareColumnsInfo = () => {
    const reviewResponses =
      user?.user?.organization_details[0]?.review_template_details[0]
        ?.review_template || {};
    if (reviewResponses) {
      let keys = Object.keys(reviewResponses);
      let index = keys.indexOf("Comment");
      if (index > -1) {
        keys.splice(index, 1);
      }
      keys.sort();
      keys.unshift("Date");
      keys.push("Comment");
      keys = keys.map((key) => {
        let render = null;
        if (key === "Date") {
          render = (dataValue) => (
            <div className="feedback-date">
              {moment(dataValue).isValid
                ? moment(dataValue).format("DD MMM YYYY")
                : dataValue}
            </div>
          );
        } else {
          render = getRender;
        }
        return {
          title: key,
          dataIndex: key,
          key: key,
          render,
          markAsHeader: key === "Comment",
        };
      });
      setReviewColumnsData(keys);
    }
  };

  const prepareSourceData = () => {
    let reviewResponses = customerReviews.customerReviews?.results;
    reviewResponses = reviewResponses.map((reviews) => {
      if (reviews.reviewResponse)
        return {
          ...reviews.reviewResponse,
          Date: reviews.createdAt,
          Comment: reviews?.reviewResponse?.Comment || "No Comments Added",
          extraData: reviews,
        };
    });
    setReviewSourceData(reviewResponses);
  };

  const handleOnRetry = (days) => {
    let fromDate = new Date();
    let toDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
    fromDate = moment(new Date())
      .subtract(days - 1, "days")
      .format("YYYY-MM-DD");
    dispatch(fetchCustomerReview({ fromDate, toDate }));
  };

  return {
    customerReviews,
    reviewColumnsData,
    reviewSourceData,
    handleOnRetry,
  };
};
