import React from "react";
import Modal from "../../../../components/Modal/index";
import "../Login/index.css";
import FormComponent from "../../../../components/FormComponent/FormComponent";
import { useSignup } from "./Signup.hook";
import ButtonMain from "../../../../components/Button/ButtonMain";
import ResponseMessage from "../../../../components/ResponseMessages";
import { IN_SHORT_NAME } from "../../../../common/Constants";
import ErrorResponse from "../ErrorResponse.component";

function Signup({ isOpen, onClose, country = "IN", toggleModal }) {
  const {
    handleForm,
    signupForm,
    handleFormSubmit,
    loading,
    signupFormModel,
    message,
    step,
    timeLeft,
    isTimerActive,
    handleResendOtp,
    passwordValidations,
    emailSignupSuccess,
    errorMessages,
  } = useSignup(country, toggleModal);
  return (
    <div>
      <Modal isOpen={isOpen} onCancel={onClose}>
        <div className="login-cotainer">
          <p className="login__header">Create an account</p>
          {<p className="login-enter-text">Enter your business details to sign up</p>}
          <form className="login-form" onSubmit={handleFormSubmit}>
            <FormComponent
              formName="signupForm"
              model={signupFormModel}
              onChange={handleForm}
              formValue={signupForm.signupForm}
              styleClass="login-form-style-class"
            />
            {/* ---------------------Mobile Auth for india-------------------- */}
            {country === IN_SHORT_NAME ? (
              <>
                <ButtonMain
                  text={step === 1 ? "Signup" : "Verify"}
                  additionalClass={"loign-btn-styles col-9"}
                  type={"submit"}
                  loading={loading}
                />
                {isTimerActive && (
                  <p className="resend-otp">Resend OTP in {timeLeft} seconds</p>
                )}
                {!isTimerActive && step !== 1 && (
                  <p
                    className="resend-otp"
                    onClick={() => {
                      handleResendOtp();
                    }}
                  >
                    Resend OTP
                  </p>
                )}
                <ResponseMessage message={message} />
              </>
            ) : (
              <>
                {/* ---------------------Mobile Auth for non-india-------------------- */}
                <ButtonMain
                  text={"Signup"}
                  additionalClass={"loign-btn-styles col-9"}
                  type={"submit"}
                  loading={loading}
                  disabled={emailSignupSuccess}
                />
                <ErrorResponse
                  text={errorMessages}
                  type={emailSignupSuccess ? "success" : "fail"}
                />
                {emailSignupSuccess && (
                  <p
                    className="signup-link"
                    style={{ fontSize: 14, textDecoration: "underline" }}
                    onClick={handleFormSubmit}
                  >
                    Didn't receive link? Resend
                  </p>
                )}
              </>
            )}
          </form>
          <p className="terms-privacy-policy">
            Already have account?{" "}
            <span className="signup-link" onClick={() => toggleModal("login")}>
              Sign in
            </span>
          </p>
          <p className="terms-privacy-policy">
            By clicking continue, you agree to our{" "}
            <a href="/terms-of-service">Terms of Service</a> and{" "}
            <a href="/privacy-policy"> Privacy Policy</a>
          </p>
          {/* <p className="divider-styles">Or continue with</p> */}
          {/* <Logingoogle /> */}
        </div>
      </Modal>
    </div>
  );
}

export default Signup;
