import { INPUT_TYPES } from "../../components/FormComponent/FormConstants";
import { shouldHaveMinLenght, validateEmail, validateSimplePassword } from "../../utils/validators";
import { validatePassword } from "../../utils/validators";

export const emailPasswordSignup = [
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "email",
    inputStyleClass: "login-input-style-class",
    styleClass:
      "login-styles-class col-md-8 col-lg-5 col-xl-4 col-sm-12 text-left",
    placeholder: "Enter your email",
    validators: [validateEmail],
    label: "Email",
  },
  {
    type: INPUT_TYPES.PASSWORD,
    disabled: false,
    value: "",
    required: true,
    fieldName: "password",
    inputStyleClass: "login-input-style-class",
    styleClass:
      "login-styles-class col-md-8 col-lg-5 col-xl-4 col-sm-12 text-left",
    placeholder: "Create a password",
    validators: [validateSimplePassword],
    onClickIcon: () => {},
    label: "Password",
  },
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "bizName",
    inputStyleClass: "login-input-style-class",
    styleClass:
      "login-styles-class col-md-8 col-lg-5 col-xl-4 col-sm-12 text-left",
    placeholder: "Business Name",
    maxLength: 50,
    label: "Business Name",
  },
  {
    label: "Business Type",
    type: INPUT_TYPES.DROP_DOWN,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "bizType",
    inputStyleClass: "login-input-style-class",
    styleClass:
      "login-styles-class col-md-8 col-lg-5 col-xl-4 col-sm-12 text-left",
    placeholder: "Business Type",
    maxLength: 10,
    options: [
      { value: "restaurant", name: "Restaurant" },
      { value: "clinic", name: "Clinic" },
      { value: "others", name: "Others" },
    ],
  },
];
