import { useForgotPassword } from "./ForgotPassword.hook";
import Modal from "../../../../components/Modal";
import TextInput from "../../../../components/Input/TextInput";
import ButtonMain from "../../../../components/Button/ButtonMain";
import { INPUT_TYPES } from "../../../../components/FormComponent/FormConstants";
import "./index.css";
import ErrorResponse from "../ErrorResponse.component";

function ForgotPassword({
  isOpen = false,
  togglemodal,
  onClose = (onClose = () => {}),
  closeForgotPassModal,
}) {
  const {
    handleForgotPassword,
    handleForgotPasswordChange,
    message,
    email,
    emailResetSuccess,
    loading,
  } = useForgotPassword();

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <div className="ForgotPass-container">
        <p className="ForgotPass__header font-bold text-xl">
          Forgot Password ?
        </p>
        <p className="p-3">Please Enter Your Registered Email Address</p>
        <TextInput
          type={INPUT_TYPES.TEXT_INPUT}
          label="Email"
          fieldName="email"
          required={true}
          containerClass=""
          value={email}
          onChange={handleForgotPasswordChange}
          styleClass=" col-9 text-input"
          placeholder="Email address"
        />
        <ButtonMain
          text="Reset Password"
          additionalClass={"forgotpass-btn-styles"}
          onClick={handleForgotPassword}
          loading={loading}
        />
        <ErrorResponse
          text={message.message}
          type={emailResetSuccess ? "success" : "fail"}
          containerClass=""
        />
        {emailResetSuccess && (
          <p
            className="signup-link"
            style={{ fontSize: 14, textDecoration: "underline" }}
            onClick={handleForgotPassword}
          >
            Didn't receive link? Resend
          </p>
        )}
      </div>
    </Modal>
  );
}
export default ForgotPassword;
