import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import "./index.css";
import FormComponent from "../../../../components/FormComponent/FormComponent";
import ButtonMain from "../../../../components/Button/ButtonMain";
import classnames from "classnames";
import { useLogin } from "./Login.hook";

import { attemptLogin } from "../Auth.actions";
import { IN_SHORT_NAME } from "../../../../common/Constants";
import Checkbox from "../../../../components/CheckBox.component";
import { INPUT_TYPES } from "../../../../components/FormComponent/FormConstants";
import Signup from "../Signup/Singup.component";
import { Flex, Row } from "antd";
import { FaAlignCenter } from "react-icons/fa6";
import ResponseMessage from "../../../../components/ResponseMessages";
import ErrorResponse from "../ErrorResponse.component";
import TextInput from "../../../../components/Input/TextInput";
import { useForgotPassword } from "../ForgotPassword/ForgotPassword.hook";
import ForgotPassword from "../ForgotPassword/ForgotPassword.component";

function Login({
  isOpen = false,
  onClose = () => {},
  country = "IN",
  toggleModal,
}) {
  const {
    handleForm,
    handleSubmit,
    loading,
    loginForm,
    message,
    loginModal,
    step,
    counter,
    showResend,
    showTimer,
    sendOtp,
    setCounter,
    setShowResend,
    setShowTimer,
    handleToggleShowPassword,
    handleLogin,
  } = useLogin(country);

  return (
    <div>
      <Modal isOpen={isOpen} onCancel={onClose}>
        <div className="login-cotainer">
          <p className="login__header">Log In</p>
          <p className="login-enter-text">Enter Email and Password</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <FormComponent
              formName="loginForm"
              model={loginModal}
              onChange={handleForm}
              formValue={loginForm.loginForm}
              styleClass="login-form-style-class"
            />
            {country === IN_SHORT_NAME ? (
              <>
                <ButtonMain
                  text={step === 1 ? "Send OTP" : "Verify OTP"}
                  additionalClass={"loign-btn-styles col-9"}
                  type={"submit"}
                  loading={loading}
                />
                <p
                  className={classnames({
                    "d-none": !message.message,
                    success: message.type === "success",
                    error: message.type === "error",
                  })}
                >
                  {message.message}
                </p>
                {step !== 1 && showTimer && (
                  <p className="resend-otp">Resend OTP in {counter} seconds</p>
                )}
                {showResend && (
                  <p
                    className="resend-otp"
                    onClick={() => {
                      sendOtp(undefined, true);
                      setShowResend(false);
                    }}
                  >
                    Resend OTP
                  </p>
                )}
                <p className="divider">
                  Don't Have an Account?{"  "}
                  <span
                    onClick={() => toggleModal("signup")}
                    className="signup-link"
                  >
                    Sign Up
                  </span>
                </p>
              </>
            ) : (
              <>
                <div className="checkbox-container">
                  <Checkbox
                    className="checkbox"
                    label=" Show Password"
                    checked={loginModal[1]?.type === INPUT_TYPES.TEXT_INPUT}
                    onChange={handleToggleShowPassword}
                  />
                  <p
                    className="forgot-password"
                    onClick={() => toggleModal("forgotpassword")}
                  >
                    Forgot Password ?
                  </p>
                </div>

                <ButtonMain
                  text={step === 1 && "Log In"}
                  additionalClass={"loign-btn-styles col-9"}
                  type={"submit"}
                  loading={loading}
                />
                {/* <p
                  className={classnames({
                    "d-none": !message.message,
                    success: message.type === "success",
                    error: message.type === "error",
                  })}
                > */}
                <ErrorResponse text={message.message} />
                {/* </p> */}

                <p className="divider">
                  Don't Have an Account?{"  "}
                  <span
                    onClick={() => toggleModal("signup")}
                    className="signup-link"
                  >
                    Sign Up
                  </span>
                </p>

                {/* <p className="terms-privacy-policy">
                  Already have account?{" "}
                  <span
                    className="signup-link"
                    onClick={() => toggleModal("login")}
                  >
                    Sign up
                  </span>
                </p> */}
              </>
            )}

            {/* <p className="divider-styles">Or continue with</p> */}
            {/* <Logingoogle /> */}
          </form>
        </div>
      </Modal>
      {/* <ForgotPassword
        isForgotPassModalOpen={isForgotPassModalOpen}
        closeForgotPassModal={closeForgotPassModal}
      /> */}
    </div>
  );
}

export default Login;
