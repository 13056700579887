import { useEffect, useState, useRef } from "react";
import { useOnFormChange } from "../../common/hooks/useOnFormChange";
import { validateForm } from "../../utils/validators";
import { emailPasswordSignup } from "./Signup.constants";
import { attemptSignup, verifyRecaptcha } from "./Signup.actions";
import { attemptEmailSignup } from "../LandingPage/Auth/Auth.actions";
import { messages } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { routes } from "../../common/routeUrls";
import { message } from "antd";
import { identifiers } from "../../utils/identifiers";
import { postUrl } from "../../common/feedbackApi";
import axios from "axios";
import { apiUrls } from "../../common/apiUrls";

export const useSignUp = () => {
  const { formData, handleFormChange, updateForm } = useOnFormChange();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    document.title = "Signup for free - QR Pro Studio";
  }, []);
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const resetCaptcha = () => {
    recaptchaRef.current?.reset(); // Call reset() method on the widget instance
  };

  const onSubmit = async (e, captcha) => {
    if (e) {
      e.preventDefault();
    }
    const validatedFields = validateForm(
      emailPasswordSignup,
      formData.signupData
    );
    updateForm({ signupData: validatedFields.newForm });
    if (!validatedFields.isValid) {
      return;
    }
    if (!captchaValue) {
      messageApi.open({
        type: "error",
        content: "Please complete the reCAPTCHA!",
      });
      return;
    }
    setLoading(true);
    try {
      // Verify captcha token on backend
      const captchaVerificationResp = await postUrl(
        apiUrls.verifyRecaptcha,
        {
          token: captchaValue,
        },
        false
      );
      if (captchaVerificationResp.status !== 200) {
        messageApi.open({
          type: "error",
          content: "reCAPTCHA verification failed!",
        });
        resetCaptcha();
        setLoading(false);
        return;
      }
      const resp = await attemptEmailSignup(formData.signupData);
      if (
        resp.data?.message === messages.SIGNUP_COMPLETED &&
        resp.status === 201
      ) {
        localStorage.clear();
        localStorage.setItem("access_token", resp.data?.access);
        localStorage.setItem("user_id", resp.data.user_id);
        localStorage.setItem(identifiers.is_new_user, true);
        navigate(routes.qrCodes);
      } else if (resp && resp?.emailAlreadyExistsError) {
        updateForm({
          signupData: {
            ...validatedFields.newForm,
            emailError: "A business with this email already exists!",
          },
        });
        resetCaptcha();
      } else {
        messageApi.open({
          type: "error",
          content: "Looks like something went wrong! Try again.",
        });
        resetCaptcha();
      }
      if (!resp.success) {
        scrollTo(0, 0);
      }
      setLoading(false);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error during reCAPTCHA verification or signup!",
      });
      console.error(error);
      resetCaptcha();
    }

    setLoading(false);
  };

  return {
    formData,
    handleFormChange,
    onSubmit,
    loading,
    contextHolder,
    handleCaptchaChange,
    captchaValue,
    recaptchaRef,
  };
};
