import { INPUT_TYPES } from "../../components/FormComponent/FormConstants";
import {
  shouldHaveMinLenght,
  validateEmail,
  validateMobileNumber,
  validateURL,
} from "../../utils/validators";

export const accountInfoModel = [
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    label: "Name",
    required: true,
    fieldName: "name",
    validators: [shouldHaveMinLenght(4)],
  },
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    label: "Business Type",
    required: true,
    fieldName: "accountType",
    disabled: true,
  },
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    label: "Email",
    required: true,
    fieldName: "email",
    validators: [validateEmail],
  },
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: true,
    value: "",
    label: "Mobile",
    required: false,
    fieldName: "mobile",
    // validators: [validateMobileNumber],
  },
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    label: "Organisation URL",
    required: false,
    fieldName: "bizUrl",
    validators: [validateURL],
    infoMessage: `
    This is the link to your business in Google. This will help customers give Google reviews.
    `,
    infoTitle: "What is this URL?",
  },
];
