import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import './index.css'

// --------------------- To be implemented using react portals --------------------

function Loader() {
  return (
    <div className="loader-portal-container">
      {/* <TailSpin
        visible={true}
        height="40"
        width="40"
        color="#3DBDFF"
        ariaLabel="tail-spin-loading"
        radius="1"
      /> */}
    </div>
  );
}

const LoaderPortal = ({ children }) => {
  const loaderRef = document.getElementById("loader-portal");
  return createPortal(children, loaderRef);
};

LoaderPortal.propTypes = {
  children: PropTypes.element.isRequired,
};

export { Loader, LoaderPortal };
