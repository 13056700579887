import React from "react";
import { useReducer } from "react";
import PropTypes from "prop-types";

const createStore = (reducer, actions, initialState) => {
  const Context = React.createContext();
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    // Take all the reducers from the actions object and bind them to the dispatch
    const boundActions = {};
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }
    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  Provider.propTypes = {
    children: PropTypes.any,
  };

  return { Context, Provider };
};

createStore.propTypes = {
  children: PropTypes.any,
};

export default createStore;
