import React from "react";
import "./index.css";
import classNames from "classnames";

function AvatarGroups({ avatars = [] }) {
  console.log(avatars);

  return (
    <div className="d-flex align-items-center avatars-container">
      {avatars.map((link, index) => {
        const positioning = (index * 13);

        return (
          <img
            id={index}
            src={link}
            alt={`image-${index}`}
            className="avatar-img"
            style={{position: "relative", right: positioning}}
          />
        );
      })}
    </div>
  );
}

export default AvatarGroups;
