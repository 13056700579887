import React from "react";
import PropTypes from "prop-types";
import { useInput } from "./useInput";
import { INPUT_TYPES } from "../FormComponent/FormConstants";
import styles from "./index.module.css";
import Tooltip from "../Tooltip/Tooltip";
import { RxInfoCircled } from "react-icons/rx";
import { Popover } from "antd";

function TextInput({
  placeholder = "",
  label = "",
  onChange = "",
  styleClass = "col-md-5 col-sm-12 col-sx-11",
  type = INPUT_TYPES.TEXT_INPUT,
  extraProps = "",
  icon = null,
  disabled = false,
  value = "",
  maxLength,
  fieldName = "",
  required = false,
  validators = [],
  error = "",
  inputStyleClass = "",
  showFlag = false,
  tooltipText = "Only Indian mobile numbers accepted",
  onClickIcon = () => {},
  infoMessage = "",
  infoTitle = "",
  ...props
}) {
  const { handleOnChangeInput } = useInput({
    fieldName,
    onChange,
    value,
    maxLength,
    validators,
    required,
  });

  return (
    <div
      className={`${styles["app-input"]} ${error ? styles["app-input__error"] : ""} ${styleClass}`}
    >
      {label && (
        <p className={`${styles["app-input-label"]}`}>
          {label}

          {infoMessage ? (
            <span>
              <Popover
                content={infoMessage}
                title={infoTitle}
                style={{ fontFamily: "Nunito Sans"}}
                overlayStyle={{maxWidth: 400}}
                placement="bottom"
              >
                <RxInfoCircled
                  size={16}
                  style={{ marginLeft: 5, marginBottom: 3 }}
                  color="rgba(32, 34, 36, 1)"
                />
              </Popover>
            </span>
          ) : null}
        </p>
      )}
      <div
        className={`${styles["app-input-container"]} ${icon !== null ? styles["icon-input"] : ""}`}
      >
        {showFlag && (
          <Tooltip text={tooltipText}>
            <img
              src={`${process.env.PUBLIC_URL}/indianFlag.png`}
              alt="Indian Flag"
              className={styles.flag}
            />
          </Tooltip>
        )}
        <input
          placeholder={placeholder}
          onChange={handleOnChangeInput}
          disabled={disabled}
          value={value}
          type={type}
          {...props}
          className={`${inputStyleClass}  ${styles["app-input-input"]}`}
          maxLength={maxLength}
        />
        {icon !== null && (
          <span onClick={onClickIcon} className={styles["text-input-icon"]}>
            {icon}
          </span>
        )}
      </div>
      {error && <small className={styles.error_msg}>{error}</small>}
    </div>
  );
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  styleClass: PropTypes.string,
  type: PropTypes.any,
  extraProps: PropTypes.object,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  fieldName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
  error: PropTypes.string,
  inputStyleClass: PropTypes.string,
  showFlag: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default TextInput;
