import React from "react";
import PropTypes from "prop-types";

function ReviewListingMobile({ columns = [], dataSource = [] }) {
  const renderData = (data, index) => {
    return (
      <div className="feeback-review-container">
        <div className="feedback-card-header">
          <p className="review-date text-right">
            {/* {columns[0].render(dataSource["Date"])} */}
            {columns[0].render(data["Date"])}
          </p>
          <p className="review-comment">{data["Comment"]}</p>
        </div>
        <div className="feedback-divider"></div>
        <div className="d-flex align-items-center justify-content-between">
          {columns.map((col) => {
            if (col.title !== "Comment" && col.title !== "Date") {
              let dataSourceValue = parseInt(data[col.dataIndex]);
              let colorIndicator =
                dataSourceValue >= 4
                  ? "#3DD598" // green
                  : dataSourceValue < 3
                    ? "#FC5A5A" // red
                    : "#36383a"; // black
              return (
                <>
                  <div className="feedback-sub-review">
                    <p className="feedback-question">{col.title}</p>
                    <p
                      className="feedback-review-answer"
                      style={{
                        color: colorIndicator,
                        borderRight:
                          index === dataSource.length - 2
                            ? "none"
                            : "1px solid #f1f1f5",
                      }}
                    >
                      {data[col.dataIndex]} Stars
                    </p>
                  </div>
                </>
              );
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      {dataSource.map((data, index) => {
        return renderData(data, index);
      })}
    </>
  );
}

ReviewListingMobile.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
};

export default ReviewListingMobile;
