import React from "react";
import "./index.css";

function Footer() {
  return (
    <footer className="app-foooter">
      <div className="d-flex flex-column">
        <p className="division-header">Product</p>
        <a href="/feedback">Get feedback via QR Code</a>
        <a href="/feedback">Review summary</a>
        <a href="/feedback">Access control</a>
        <a href="/feedback">Templates</a>
        <a href="/feedback">All features</a>
        <a href="/feedback">Pricing</a>
      </div>
      <div className="d-flex flex-column">
        <p className="division-header">Guides</p>
        <a href="/feedback">How to create Qr Code</a>
        <a href="/feedback">Feedback Process</a>
        <a href="/feedback">User Access </a>
      </div>
      <div className="d-flex flex-column">
        <p className="division-header">About Asterteq</p>
        <a href="/feedback">About us</a>
        <a href="/feedback">Careers</a>
        <a href="/feedback">Contact us</a>
        <a href="/privacy-policy.html">Privacy Policy</a>
      </div>
    </footer>
  );
}

export default Footer;
