export const SET_LOADING = "SET_LOADING";
export const UNSET_LOADING = "UNSET_LOADING";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UNSET_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
