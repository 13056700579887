import React from "react";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";

function ButtonMain({
  text,
  onClick = () => {},
  loading,
  disabled = false,
  additionalClass = "",
  children,
  type
}) {
  const handleOnClick = () => {
    onClick();
  };

  return (
    <button
      className={`learn-more-btn qr-codes-btn signup-btn ${additionalClass}`}
      onClick={() => handleOnClick()}
      disabled={disabled || loading}
      type={type}
    >
      {loading ? (
        <>
          <TailSpin
            visible={true}
            height="30"
            width="30"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </>
      ) : text ? (
        text
      ) : (
        <>{children}</>
      )}
    </button>
  );
}

ButtonMain.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([undefined])]),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([undefined, ""]),
  ]),
  children: PropTypes.any,
};

export default React.memo(ButtonMain);
