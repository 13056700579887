import React from "react";
import "./index.css";
import { convertToCamelCase } from "../../utils/utilities";
import QrCodes from "./QrCodes";
import withUser from "../../hoc/withUser";

function QrcodesListing({user}) {
  let bizDetails = convertToCamelCase(user?.organization_details[0]);

  return (
    <div className="qr-codes-container">
      <p className="qr-codes-heading">QR Codes</p>
      <div className="feedback-qrcodes">
        <QrCodes
          bizType={bizDetails?.bizType}
          orgName={bizDetails?.orgName}
          createdOn={bizDetails.createdAt}
          qrDownloadLink={
            bizDetails?.reviewTemplateDetails?.[0].reviewQrcodePdf
          }
          qrImageLink={bizDetails?.reviewTemplateDetails?.[0].reviewQrcodeImage}
          qrType={"Feedback"}
          totalScans={bizDetails?.reviewTemplateDetails?.[0].reviewCount}
        />
      </div>
    </div>
  );
}

const QrCodeWithUser = withUser(QrcodesListing);

export default QrCodeWithUser
