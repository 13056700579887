import { createSlice } from "@reduxjs/toolkit";
import { fetchCustomerReview } from "../HomePage/customerReviewSlice";

/**
 * markAsRead flag is used for remembering the notifcation fot the day
 * i.e if the notifcation is shown on jan-1-2024 again that notification
 * should be on the other days! (To be implemented)
 */

const initialState = {
  title: "",
  notify: false,
  type: "info",
  markAsRead: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    toggleNotification: (state, action) => {
      if (state.notify) {
        state.notify = false;
        state.title = "";
      } else {
        state.notify = true;
        state.title = action.payload;
      }
    },
    showNotification: (state, action) => {
      state.notify = true;
      state.title = action.payload;
    },
    closeNotification: (state, action) => {
      state.notify = false;
      state.title = "";
    },
    notifyWithType: (state, action) => {
      state.notify = true;
      state.type = action.payload.type;
      state.title = action.payload.title;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerReview.rejected, (state, action) => {
      state.notify = true;
      state.title = "Unable to fetch reviews at the moment!";
      state.type = "failure";
    });
  },
});

export const {
  toggleNotification,
  showNotification,
  closeNotification,
  notifyWithType,
} = notificationSlice.actions;
export default notificationSlice.reducer;
