import { useEffect, useState } from "react";
import { emailPasswordSingup, signupModel } from "./Signup.constants";
import { validateForm, validatePassword } from "../../../../utils/validators";
import { attemptEmailSignup, attemptLogin } from "../Auth.actions";
import { createOrganization } from "../Auth.actions";
import { otpModal } from "../Login/Login.constants";
import { verifyOtp } from "../Auth.actions";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../common/routeUrls";
import { identifiers } from "../../../../utils/identifiers";
import { IN_SHORT_NAME } from "../../../../common/Constants";
import { INPUT_TYPES } from "../../../../components/FormComponent/FormConstants";
import { FiEye } from "react-icons/fi";
import { LuEyeOff } from "react-icons/lu";

export const useSignup = (country, toggleModal) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [signupForm, setSignupForm] = useState({
    signupForm: {},
  });
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState({ message: "", type: "" });
  const [signupFormModel, setSignupFormModel] = useState(
    country === IN_SHORT_NAME ? signupModel : emailPasswordSingup
  );
  const [passwordValidations, setPasswordValidations] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [emailSignupSuccess, setEmailSignupSuccess] = useState(false);

  useEffect(() => {
    let timer;
    if (isTimerActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsTimerActive(false);
    }

    return () => clearInterval(timer);
  }, [isTimerActive, timeLeft]);

  const handleForm = (formName, value, fieldName) => {
    setSignupForm((prevForm) => {
      return {
        ...prevForm,
        [formName]: {
          ...prevForm[formName],
          [fieldName]: value,
        },
      };
    });
    if (fieldName === "password") {
      const passwordValidations = validatePassword(value);
      setPasswordValidations(
        !passwordValidations?.valid ? passwordValidations.failedValidations : []
      );
    }
    if (fieldName === "email") {
      setErrorMessages("");
    }
  };

  const handleToggleShowPassword = () => {
    setSignupFormModel((prev) => {
      if (country !== IN_SHORT_NAME) {
        let passwordField = prev[1];
        passwordField.type =
          passwordField.type === INPUT_TYPES.PASSWORD
            ? INPUT_TYPES.TEXT_INPUT
            : INPUT_TYPES.PASSWORD;
        passwordField.icon =
          passwordField.type === INPUT_TYPES.PASSWORD ? (
            <LuEyeOff
              size={17}
              style={{ marginRight: "10px", marginLeft: "5px" }}
              color="#C2C2C2"
            />
          ) : (
            <FiEye
              size={17}
              style={{ marginRight: "10px", marginLeft: "5px" }}
              color="#C2C2C2"
            />
          );
        prev[1] = passwordField;
        return [...prev];
      }
    });
  };

  const verify = async (e) => {
    setMessage({});
    if (e) {
      e.preventDefault();
    }
    const { newForm, isValid } = validateForm(
      signupFormModel,
      signupForm.signupForm
    );
    setSignupForm({ signupForm: { ...newForm } });
    if (!isValid) {
      return;
    }
    setLoading(true);
    const result = await verifyOtp(signupForm.signupForm);
    if (result.success) {
      localStorage.setItem(identifiers.is_new_user, true);
      navigate(routes.qrCodes);
    } else if (result.invalidOtp) {
      setSignupForm({
        signupForm: {
          ...newForm,
          otpError: "Invalid OTP",
        },
      });
    } else {
      setMessage({
        message: "Unable to verify. Try again later!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleEmailSignup = async (e) => {
    setMessage({});
    setErrorMessages("");
    const { newForm, isValid } = validateForm(
      signupFormModel,
      signupForm.signupForm
    );
    const passwordValidations = validatePassword(
      signupForm.signupForm?.password
    );
    setPasswordValidations(
      !passwordValidations?.valid ? passwordValidations.failedValidations : []
    );
    setSignupForm({ signupForm: { ...newForm } });
    if (!isValid) {
      return;
    }
    setLoading(true);
    const response = await attemptEmailSignup(signupForm.signupForm);
    if (response?.emailAlreadyExistsError) {
      setErrorMessages(
        "Looks like you're one step ahead! There's already an account with this email."
      );
      setEmailSignupSuccess(false);
    } else if (!response.success) {
      setEmailSignupSuccess(false);
      setErrorMessages("Something went wrong!");
    } else if (response.success) {
      setEmailSignupSuccess(true);
      setErrorMessages(
        `We sent a verification link to ${signupForm.signupForm.email}`
      );
      setSignupFormModel((prev) => {
        return prev.map((model) => {
          return { ...model, disabled: true };
        });
      });
    }
    setLoading(false);
  };

  const handleFormSubmit = (e) => {
    if (country !== IN_SHORT_NAME) {
      e.preventDefault();
      handleEmailSignup(e);
    } else {
      if (step === 1) {
        handleOnsubmit(e);
      } else {
        verify(e);
      }
    }
  };

  const handleOnsubmit = async (e) => {
    setMessage({});
    if (e) e.preventDefault();
    const { newForm, isValid } = validateForm(
      signupModel,
      signupForm.signupForm
    );
    setSignupForm({ signupForm: { ...newForm } });
    if (!isValid) {
      return;
    }
    setLoading(true);
    const response = await attemptLogin(signupForm.signupForm);
    if (response.orgNotFound === true && response.success === false) {
      const createOrgResponse = await createOrganization(signupForm.signupForm);
      if (createOrgResponse.success) {
        setMessage({
          message: "Verification code sent to your mobile",
          type: "success",
        });
        setSignupFormModel((prevState) => {
          let filteredModel = prevState.filter(
            (item) =>
              item.fieldName !== "bizName" && item.fieldName !== "bizType"
          );
          filteredModel[0].disabled = true;
          filteredModel.push(otpModal);
          return filteredModel;
        });
        setIsTimerActive(true);
        setStep(2);
      } else {
        setMessage({
          message: "Something went wrong! Try again later.",
          type: "error",
        });
      }
    } else if (response.orgNotFound === false) {
      setSignupForm({
        signupForm: {
          ...newForm,
          mobileError: "A business already exists with this mobile.",
        },
      });
    } else {
      setMessage({
        message: "Something went wrong! Try again later.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleResendOtp = async () => {
    setMessage({});
    const { newForm, isValid } = validateForm(
      signupModel,
      signupForm.signupForm
    );
    setSignupForm({ signupForm: { ...newForm } });
    if (!isValid) {
      return;
    }
    setLoading(true);
    const response = await attemptLogin(signupForm.signupForm);
    if (response.maxLimitReached) {
      setMessage({
        message: "You have requested maximum OTP's for today!",
        type: "error",
      });
    } else if (response.success) {
      setMessage({
        message: "Verification code sent to your mobile",
        type: "success",
      });
      setTimeLeft(30);
      setIsTimerActive(true);
    }
    setLoading(false);
  };

  return {
    handleForm,
    signupForm,
    handleFormSubmit,
    loading,
    signupFormModel,
    message,
    step,
    timeLeft,
    isTimerActive,
    handleResendOtp,
    passwordValidations,
    errorMessages,
    emailSignupSuccess,
  };
};
