import React from "react";
import "../index.css";
import Filters from "../Filters/Filter.component";
import ReviewListing from "../ReviewListing/ReviewListing.component";

function CustomerReview() {
  return (
    <div className="dashboard-body">
      <p className="dashboard-heading">Customer Reviews</p>
      <Filters />
      <ReviewListing />
    </div>
  );
}

export default CustomerReview;
