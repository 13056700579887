import React, { useEffect } from "react";
import "./index.css";
import Button from "../../components/Button/ButtonV2";
import { GoForwardIcon, Star } from "../../common/svgIcons";
import Logo from "../../components/Logo/Logo";
import AvatarGroups from "../../components/AvatarGroups/AvatarGroups";

function SignupOkay() {
  useEffect(() => {
    document.title = "Verification Email sent";
  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleOpenMail = () => {
    console.log(navigator.userAgent);
    if (isMobile) {
      window.open("https://mail.google.com", "_blank");
    } else {
      window.location.href = "mailto:";
    }
  };

  return (
    <div className="container">
      <div className="row p-3">
        <div
          className="col-md-12 d-flex flex-column align-items-center justify-content-center text-center"
          style={{ height: "80vh" }}
        >
          <div
            style={{
              maxWidth: 400,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Logo />
            <p className="you-have-got-email mb-3 mt-2">You’ve got mail !</p>
            <p className="sub-text m-3">
              We've sent an email to your inbox. Please go to your email and
              click the{" "}
              <span style={{ color: "#2E90FA", fontWeight: "700" }}>
                Verify
              </span>{" "}
              button to continue. You are just one click away!{" "}
              <span>&#128521;</span>
            </p>
            {/* <Button
              className="w-100 resend-link-btn email-button"
              text="Go to email"
              icon={<GoForwardIcon />}
              onClick={handleOpenMail}
            /> */}
            <div className="d-flex align-items-center mt-4">
              <AvatarGroups
                avatars={[
                  "/avatars/avatar_1.png",
                  "/avatars/avatar_2.png",
                  "/avatars/avatar_3.png",
                  "/avatars/avatar_4.png",
                  "/avatars/avatar_5.png",
                ]}
              />
              <div style={{marginLeft: -30}}>
                <div className="d-flex align-items-center">
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </div>
                from 200+ reviews
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupOkay;
