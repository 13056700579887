import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUrl } from "../../../common/feedbackApi";
import { apiUrls } from "../../../common/apiUrls";

const INTIAL_STATE = {
  user: null,
  loading: false,
  isLoggedIn: false,
  error: null
};

export const fetchUser = createAsyncThunk(
  "user/getUserById",
  async (userId, _) => {
    const response = await getUrl(apiUrls.userDetails(userId));
    if(response.success === false){
      throw new Error("Error while fetching data")
    }
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: INTIAL_STATE,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.user = null;
        state.error = "Error occured while getting user details!";
        state.loading = false;
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      });
  },
});

export default userSlice.reducer