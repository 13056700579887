import * as React from "react";
const CheckedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    {...props}
  >
    <g filter="url(#filter0_di_1880_1042)">
      <mask id="path-1-inside-1_1880_1042" fill="white">
        <path d="M2 7.2C2 4.32812 4.32812 2 7.2 2H18.8C21.6719 2 24 4.32812 24 7.2V18.8C24 21.6719 21.6719 24 18.8 24H7.2C4.32812 24 2 21.6719 2 18.8V7.2Z" />
      </mask>
      <path
        d="M2 7.2C2 4.32812 4.32812 2 7.2 2H18.8C21.6719 2 24 4.32812 24 7.2V18.8C24 21.6719 21.6719 24 18.8 24H7.2C4.32812 24 2 21.6719 2 18.8V7.2Z"
        fill="#23AA79"
      />
      <path
        d="M2 2H24H2ZM24 18.8C24 22.5003 21.0003 25.5 17.3 25.5H8.7C4.99969 25.5 2 22.5003 2 18.8C2 20.8435 4.32812 22.5 7.2 22.5H18.8C21.6719 22.5 24 20.8435 24 18.8ZM2 24V2V24ZM24 2V24V2Z"
        fill="#2DC38C"
        mask="url(#path-1-inside-1_1880_1042)"
      />
    </g>
    <g filter="url(#filter1_d_1880_1042)">
      <path
        d="M8 12L10.6149 15.1379C10.9976 15.5971 11.695 15.6202 12.1073 15.1874L18 9"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_di_1880_1042"
        x={1}
        y={-0.5}
        width={24}
        height={25.5}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={1}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_1880_1042"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.806057 0 0 0 0 0.943424 0 0 0 0 0.893056 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1880_1042"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1880_1042"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={-2.5} />
        <feGaussianBlur stdDeviation={1.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.337255 0 0 0 0 0.843137 0 0 0 0 0.654902 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1880_1042"
        />
      </filter>
      <filter
        id="filter1_d_1880_1042"
        x={3}
        y={6}
        width={20}
        height={16.4978}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1880_1042"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1880_1042"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default CheckedIcon;
