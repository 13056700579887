import React from "react";
import { LuFilter } from "react-icons/lu";
import "../index.css";
import { useFilters } from "./Filter.hook";
import AppIcon from "../../../components/AppIcons/AppIcon";
import { APP_ICONS } from "../../../components/AppIcons/IconConstants";
import DateInput from "../../../components/DateInput/DateInput";
import Dropdown from "../../../components/Dropdown/Dropdown.component";

const FILTERS = {
  today: 1,
  oneWeekAgo: 7,
  oneMonthAgo: 30,
  onYearAgo: 365,
  custom: -1,
};

function Filters() {
  const {
    currentFilter,
    handleAddFilter,
    clearFilters,
    handleDateChange,
    selectedCustomDate,
    handleDropdownChange,
  } = useFilters();
  return (
    <div className="filters">
      <div className="filter-icon filter-icon-icon filter-icon-icon-1">
        <button>
          <LuFilter color="#202224" size={18} className="mr-1" />
          {/* Filter by */}
        </button>
      </div>
      <div className="filter-icon">
        <button
          className={`filter-btn ${currentFilter === FILTERS.today && "active-filter"}`}
          onClick={() => handleAddFilter(FILTERS.today)}
        >
          Today
        </button>
      </div>
      <div className="filter-icon">
        <button
          className={`filter-btn ${currentFilter === FILTERS.oneWeekAgo && "active-filter"}`}
          onClick={() => handleAddFilter(FILTERS.oneWeekAgo)}
        >
          Last 7 days
        </button>
      </div>
      <div className="filter-icon">
        <button
          className={`filter-btn ${currentFilter === FILTERS.oneMonthAgo && "active-filter"}`}
          onClick={() => handleAddFilter(FILTERS.oneMonthAgo)}
        >
          Last 30 days
        </button>
      </div>
      <div
        className={`d-flex align-items-center dp-container cursor-pointer ${currentFilter === FILTERS.custom && "active-filter"}`}
      >
        <DateInput
          inputStyleClass={"date-picker-styles cursor-pointer"}
          onChange={handleDateChange}
          fieldName="fromToDateFilter"
          value={selectedCustomDate}
        />
      </div>
      <div className="filter-icon last reset filter-icon-icon-1">
        <button className={`filter-btn d-flex`} onClick={() => clearFilters()}>
          <AppIcon
            path={APP_ICONS.reset}
            width="16"
            height="16"
            className={"mr-1"}
            fill="#EA0234"
          ></AppIcon>
          Reset Filter
        </button>
      </div>
      <div className="mobile-filters row">
        <Dropdown
          options={[
            {
              name: "Today",
              value: FILTERS.today,
            },
            {
              name: "Last 7 days",
              value: FILTERS.oneWeekAgo,
            },
            {
              name: "Last 30 days",
              value: FILTERS.oneMonthAgo,
            },
            {
              name: "Custom",
              value: FILTERS.custom,
            },
            {
              name: "Clear filters",
              value: 100
            }
          ]}
          fieldName="reviewFilters"
          onChange={handleDropdownChange}
          placeholder="Select any filter"
          styleClass={"col-md-9 col-sm-12 mb-0"}
          label="Select any filter"
          value={currentFilter}
        />
        {currentFilter === FILTERS.custom && (
          <div style={{marginTop: "1rem"}}>
            <DateInput
              fieldName="customDateRange"
              value={selectedCustomDate}
              onChange={handleDateChange}
              placeholder={"Select a date range"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Filters;
