import React, { useEffect, useRef } from "react";
import "./index.css";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";

import PropTypes from "prop-types";
import styles from "../Input/index.module.css";
import { INPUT_TYPES } from "../FormComponent/FormConstants";
import cn from "classnames";
import { v4 as uuidv4 } from "uuid";

function Dropdown({
  placeholder = "Please select",
  label = "",
  onChange = () => {},
  styleClass = "col-md-12 col-sm-12 col-sx-11",
  type = INPUT_TYPES.DROP_DOWN,
  extraProps = "",
  icon = null,
  disabled = false,
  value = "",
  maxLength,
  fieldName = "",
  required = false,
  validators = [],
  error = "",
  inputStyleClass = "",
  options = [],
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  let ChevronIcon = FaChevronDown;
  const dropdownRef = useRef(null);
  const optionsRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (dropdownRef.current && optionsRef.current) {
      optionsRef.current.style.width = `${dropdownRef.current.offsetWidth}px`;
    }
  }, [isOpen]);

  const handleOnChange = (value, fieldName, optionFullValue) => {
    toggleDropdown();
    onChange(value, fieldName, optionFullValue);
  };

  const getNameBasedOnValue = (value, options) => {
    if (value) {
      const option = options.find((option) => option.value === value);
      return option ? option.name : "";
    }
    return "";
  };

  return (
    <div
      className={`${styles["app-input"]} ${error ? styles["app-input__error"] : ""} ${styleClass} `}
    >
      {label && <p className={`${styles["app-input-label"]} `}>{label}</p>}
      <div
        className={cn("d-flex align-items-center dropdown-container", {
          closed: !isOpen,
          open: isOpen,
        })}
      >
        <input
          tabIndex={"1"}
          placeholder={placeholder}
          disabled={disabled}
          value={getNameBasedOnValue(value, options)}
          {...props}
          className={inputStyleClass}
          maxLength={maxLength}
          readOnly
          onClick={toggleDropdown}
          style={{ cursor: "pointer" }}
          ref={dropdownRef}
          // onBlur={toggleDropdown}
        />
        <span style={{ position: "absolute", right: "1.15rem" }}>
          <ChevronIcon color="#D2D2D2" size={15} className="mb-1 app-chevron" />
        </span>
      </div>
      {error && <small className={`${styles.error_msg}`}>{error}</small>}
      <div
        className={cn("dd-options", {
          open: isOpen,
          close: !isOpen,
        })}
        ref={optionsRef}
      >
        {options.map((option) => {
          return (
            <div
              className={cn("dd-option", {
                selected: value === option.value,
              })}
              key={uuidv4()}
              onClick={(e) => {
                e.stopPropagation();
                handleOnChange(option.value, fieldName, option);
              }}
            >
              {option.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      fullValue: PropTypes.any,
    })
  ),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  styleClass: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  extraProps: PropTypes.object,
  icon: PropTypes.element,
  maxLength: PropTypes.number,
  validators: PropTypes.arrayOf(PropTypes.func) || PropTypes.array,
  error: PropTypes.string,
  inputStyleClass: PropTypes.string,
};

export default Dropdown;
