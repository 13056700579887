class StringUtils {
  static capitalize(inputString) {
    if (typeof inputString !== "string") {
      return inputString;
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  static sort(listOfStrings = []) {
    return listOfStrings.sort()
  }

}

export default StringUtils;
