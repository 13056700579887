import { useState } from "react";
import { attemptPasswordReset } from "../Auth.actions";
import { validateEmail } from "../../../../utils/validators";

export const useForgotPassword = () => {
  const [isForgotPassModalOpen, setForgotPassModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [emailResetSuccess, setEmailResetSuccess] = useState(false);

  const handleForgotPasswordChange = (email, _) => {
    setEmail(email);
  };

  const openForgotPassModal = () => {
    setForgotPassModalOpen(true);
  };

  const closeForgotPassModal = () => {
    setForgotPassModalOpen(false);
  };

  const handleForgotPassword = async () => {
    const emailValidation = validateEmail(email);

    if (!emailValidation.valid) {
      setMessage({
        message: emailValidation.message,
        type: "error",
      });
      return;
    }
    const resetData = {
      email: email,
    };

    setLoading(true);

    const result = await attemptPasswordReset(resetData);

    if (result.success) {
      setEmailResetSuccess(true);
      setMessage({
        message: `Password reset link has been sent to  ${email}`,
        type: "success",
      });
    } else if (result.emailNotFoundError) {
      setEmailResetSuccess(false);
      setMessage({
        message: "Email not found.",
        type: "error",
      });
    } else {
      setMessage({
        message: "An error occurred while processing your request.",
        type: "error",
      });
    }

    setLoading(false);
  };
  return {
    handleForgotPassword,
    closeForgotPassModal,
    openForgotPassModal,
    handleForgotPasswordChange,
    isForgotPassModalOpen,
    message,
    email,
    emailResetSuccess,
    loading,
  };
};
