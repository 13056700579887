export default function Checkbox({ label, onChange, checked, ...props }) {
  return (
    <div>
      <label>
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          {...props}
        />
        {label}
      </label>
    </div>
  );
}
