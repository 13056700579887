import React from "react";
import { v4 } from "uuid";
import PropTypes from "prop-types";
import "./index.css";
import Page from "../Pagination/Page";
import { CDN_URL } from "../../common/Constants";

function Table({
  dataSource = [],
  columns = [],
  useFlex = true,
  pagination = true,
  emptyMessage = "No results found. Trying adding other filters !",
  loading = false,
  retryButton = null,
  tryOtherFilterButton = null,
}) {
  const colSize = columns.length;

  const renderHeaders = () => {
    let headers = columns.map((cols) => cols.title);
    return headers.map((headerTitle) => {
      if (useFlex) {
        const w = 100 / (colSize + 2);
        return (
          <div
            className={`f-table-cell --header`}
            style={{ width: `${w}%` }}
            key={v4()}
          >
            {headerTitle}
          </div>
        );
      } else {
        return (
          <td className="app-table-headers" key={v4()}>
            {headerTitle}
          </td>
        );
      }
    });
  };

  const handleRender = (column, data, index) => {
    if (useFlex) {
      if (column?.render !== null && column?.render !== undefined) {
        return (
          <>
            <div
              className={`f-table-cell ${index === 0 && "--header"}`}
              style={{ width: `${100 / colSize}%` }}
            >
              <div className="f-table--header">{column.title}</div>
              <div className="f-data">
                {column.render(data[column.dataIndex], column.dataIndex)}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div
            className={`f-table-cell ${index === 0 && "--header"}`}
            style={{ width: `${100 / colSize}%` }}
            key={`${data.id}-${index}`}
          >
            {data[column.dataIndex]}
          </div>
        );
      }
    } else {
      if (column?.render !== null && column?.render !== undefined) {
        return (
          <td>{column.render(data[column.dataIndex], column.dataIndex)}</td>
        );
      } else {
        return (
          <td className="table-row-data" key={`${data.id}-${index}`}>
            {data[column.dataIndex]}
          </td>
        );
      }
    }
  };

  const renderTableSourceData = () => {
    return dataSource.map((data) => {
      return (
        <tr className="apple-data-table-row" key={data.id}>
          {columns.map((column, index) => {
            return handleRender(column, data, index);
          })}
        </tr>
      );
    });
  };

  const renderFlexTable = () => {
    return dataSource.map((data, index) => {
      return (
        <div className="f-table-row" key={data.id}>
          {columns.map((colName, index) => {
            return handleRender(colName, data, index);
          })}
        </div>
      );
    });
  };

  const renderTable = () => {
    if (!dataSource.length && !loading) {
      return (
        <div className="message-container">
          <img
            src={`${CDN_URL}/no_results_found.jpeg`}
            className="no-result-found"
            alt="no results found"
          />
          <p>{emptyMessage}</p>
          <div>
            {retryButton}
            {tryOtherFilterButton}
          </div>
        </div>
      );
    }

    if (useFlex) {
      return (
        <>
          <div className="app-table-wrapper">
            <div className="f-table --collapse">
              <div className="f-table-row --header-row">{renderHeaders()}</div>
              <>{renderFlexTable()}</>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="app-feedback-table-container">
          <table className="app-table --collapse">
            <tbody>
              <tr>{renderHeaders()}</tr>
              {renderTableSourceData()}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <>
      {renderTable()}
      {/* {pagination && <Page total={dataSource.length} currentPage={1} />} */}
    </>
  );
}

Table.propTypes = {
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  useFlex: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Table;
