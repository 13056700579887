export const routes = {
  join: "/",
  home: "/home",
  settings: "/settings",
  //analytics: '/analytics',
  qrCodes: "/qr-codes",
  testHome: "/test-home",
  testQrs: "/test-qrs",
  testAccount: "/test-account",
  logout: "/logout",
  howItWorks: "howItWorks",
  signupOkay: "/signup/success",
  login: "/login"
};
