import axios from "axios";
import { apiBaseUrl } from "./apiUrls";

const logout = () => {
  localStorage.clear();
  window.location.href = "/";
};

export const getUrl = async (url) => {
  const authToken = localStorage.getItem("access_token");
  try {
    const result = await axios.get(apiBaseUrl.baseUrl + url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return { status: result.status, data: result.data, success: true };
  } catch (err) {
    if (err?.response?.data?.code) {
      if (err.response.data.code === "token_not_valid") {
        logout();
      }
    } else {
      return {
        status:
          err?.response?.status || err?.status || err?.response?.data?.status,
        data: undefined,
        success: false,
      };
    }
  }
};

export const postUrl = async (url, payload, auth = true) => {
  const authToken = localStorage.getItem("access_token");
  try {
    if (auth) {
      const result = await axios.post(apiBaseUrl.baseUrl + url, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return { status: result.status, data: result.data, success: true };
    } else {
      const result = await axios.post(apiBaseUrl.baseUrl + url, payload);
      return { status: result.status, data: result.data, success: true };
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.data?.code) {
      if (err.response.data.code === "token_not_valid") {
        logout();
      }
    } else {
      return {
        status:
          err?.response?.status || err?.status || err?.response?.data?.status,
        data: err?.response?.data,
        success: false,
      };
    }
  }
};

export const putUrl = async (url, payload, auth = true) => {
  const authToken = localStorage.getItem("access_token");
  try {
    if (auth) {
      const result = await axios.put(apiBaseUrl.baseUrl + url, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return { status: result.status, data: result.data, success: true };
    } else {
      const result = await axios.put(apiBaseUrl.baseUrl + url, payload);
      return { status: result.status, data: result.data, success: true };
    }
  } catch (err) {
    console.log(err);
    if (err?.response?.data?.code) {
      if (err.response.data.code === "token_not_valid") {
        logout();
      }
    } else {
      return {
        status:
          err?.response?.status || err?.status || err?.response?.data?.status,
        data: err?.response?.data,
        success: false,
      };
    }
  }
};
