import React from "react";
import PropTypes from "prop-types";

function AppIcon({
  path,
  fill = "#202224",
  width = "20",
  height = "18",
  className,
  viewBox = "0 0 20 18",
  y,
  x,
}) {
  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        // xmlns="http://www.w3.org/2000/svg"
        y={y}
        x={x}
      >
        <path d={`${path}`} fill={fill} />
      </svg>
    </div>
  );
}

AppIcon.propTypes = {
  path: PropTypes.string.isRequired,
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  x: PropTypes.string,
  y: PropTypes.string,
  viewBox: PropTypes.string
};

export default AppIcon;
