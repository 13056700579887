import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUrl } from "../../../common/feedbackApi";
import { apiUrls } from "../../../common/apiUrls";

const INTIAL_STATE = {
  loading: false,
  error: "",
  qrCodes: {},
};

export const fetchQrCodes = createAsyncThunk(
  "qrcodes/getQrCodes",
  async (payload, thunkApi) => {
    let userId = payload.userId;
    if (userId === undefined || userId === null) {
      thunkApi.rejectWithValue("User id cannot be undefined");
    }
    try {
      const response = await getUrl(apiUrls.userDetails(userId));
    } catch (err) {
      thunkApi.rejectWithValue();
    }
  }
);

export const qrCodesSlice = createSlice({
  name: "qrCodes",
  initialState: INTIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQrCodes.pending, (state, action) => {
        state.loading = true;
        state.qrCodes = action.payload;
      })
      .addCase(fetchQrCodes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(fetchQrCodes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.qrCodes = action.payload;
      });
  },
});

export const {} = qrCodesSlice.actions;
export default qrCodesSlice.reducer;
