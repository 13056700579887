import { useEffect, useState } from "react";
import { fetchCustomerReview } from "../../../main/slices/HomePage/customerReviewSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
const FILTERS = {
  today: 1,
  oneWeekAgo: 7,
  oneMonthAgo: 30,
  onYearAgo: 365,
  custom: -1,
};

export const useFilters = () => {
  const [currentFilter, setCurrentFilter] = useState(7);
  const [datePickerVisible, setDatePickerVisisble] = useState(false);
  const [selectedCustomDate, setSelectedCustomDate] = useState(undefined);
  const dispatch = useDispatch();
  useEffect(() => {
    if (FILTERS.custom === currentFilter) {
      setDatePickerVisisble(!datePickerVisible);
      handleAddFilter(-1);
      return;
    }

    let fromDate = moment(new Date()).utc().format("YYYY-MM-DD");
    let toDate = moment(new Date()).utc().add(1, "day").format("YYYY-MM-DD");
    fromDate = moment(new Date())
      .utc()
      .subtract(currentFilter - 1, "days")
      .format("YYYY-MM-DD");
    dispatch(fetchCustomerReview({ fromDate, toDate }));
  }, [currentFilter]);

  const handleAddFilter = (currentFilter) => {
    setCurrentFilter(currentFilter);
  };

  const clearFilters = () => {
    setCurrentFilter(1);
    setSelectedCustomDate(undefined);
  };

  const handleDateChange = (field, dateRange) => {
    let fromDate = moment(dateRange[0]).format("YYYY-MM-DD");
    let toDate = moment(dateRange[1]).add(1, "day").format("YYYY-MM-DD");
    setSelectedCustomDate(dateRange);
    setCurrentFilter(-1);
    dispatch(fetchCustomerReview({ fromDate, toDate }));
  };

  const handleDropdownChange = (value) => {
    if (value === 100) {
      handleAddFilter(7);
    } else {
      handleAddFilter(value);
    }
    setSelectedCustomDate(undefined);
  };

  return {
    currentFilter,
    handleAddFilter,
    clearFilters,
    datePickerVisible,
    handleDateChange,
    selectedCustomDate,
    handleDropdownChange,
  };
};
