import React, { useEffect } from "react";
import "../index.css";
import Logo from "../../../components/Logo/Logo";
import { Progress } from "antd";
import { useAutoLogin } from "./AutoLogin.hook";
import { RxCrossCircled } from "react-icons/rx";
import Button from "../../../components/Button/ButtonV2";
import { Link } from "react-router-dom";

function AutoLogin() {
  const {
    messages,
    progressStatus,
    actionButtonType,
    handleResendVerification,
  } = useAutoLogin();
  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center m-3">
      <div className="progress-container d-flex flex-column align-items-center justify-content-center">
        <Logo />
        <div className="mt-4">
          {messages && <p className="progress_message">{messages}</p>}
          <div className="mt-3 mbt-3">
            <Progress
              showInfo={false}
              percent={progressStatus.percentage}
              status={progressStatus.status}
            />
            {/* {progressStatus.status === "exception" && <RxCrossCircled color="red" className="ms-2"/>} */}
          </div>
        </div>
        {actionButtonType === "resend" && (
          <Button
            className="w-100 resend-link-btn"
            text="Resend verification link"
            onClick={() => handleResendVerification()}
          />
        )}
        {actionButtonType === "login" && (
          <Link to={"/?login=true"} className="w-100">
            <Button className="w-100 resend-link-btn" text="Login" />
          </Link>
        )}
      </div>
    </div>
  );
}

export default AutoLogin;
