import React from "react";
import "./index.css";
import Footer from "../LandingPage/Footer";
import ButtonMain from "../../components/Button/ButtonMain";
import { CDN_URL } from "../../common/Constants";

function ErrorPage() {
  return (
    <div>
      <header className="app-error-header">
        <a href="/">
          <img
            class="app-error-header-logo"
            src={`${CDN_URL}/asterteq-ventures-logo-1.png`}
            alt="app-logo"
          />
        </a>
      </header>
      <section className="error-page-body">
        <img src={`${CDN_URL}/broken.jpg`} alt="broken link" className="broken-link-img" />
        <h1>Hmmm! This is not expected.</h1>
        <div className="d-flex">
          <ButtonMain
            text={"Report us"}
            additionalClass={"report-btn-add-class w-50"}
          ></ButtonMain>
          <ButtonMain additionalClass={"report-btn-add-class w-50"}>
            <a href="/" className="href-a">
              Go Home
            </a>
          </ButtonMain>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default ErrorPage;
