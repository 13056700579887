import { getUrl, postUrl } from "../../../common/feedbackApi";
import { apiUrls } from "../../../common/apiUrls";
import axios from "axios";
import { IN_SHORT_NAME } from "../../../common/Constants";
import { messages } from "../../../utils/constants";

export const attemptLogin = async (payload) => {
  try {
    const result = await postUrl(
      apiUrls.verifyUser,
      { mobile: payload.mobile },
      false
    );
    if (result.status === 201 && result.data?.is_organization_added) {
      return {
        success: true,
        orgNotFound: false,
      };
    } else if (
      result.status === 201 &&
      result.data?.is_organization_added === false
    ) {
      return {
        success: false,
        orgNotFound: true,
      };
    } else if (result.status === 400) {
      return {
        success: false,
        maxLimitReached: true,
      };
    } else {
      return { success: false };
    }
  } catch (err) {
    return { success: false };
  }
};

export const verifyOtp = async (payload) => {
  try {
    const result = await postUrl(
      apiUrls.verifyMobileOtp,
      { mobile: payload.mobile, otp: payload.otp },
      false
    );

    if (result.status === 201) {
      if (
        result.data?.access_token === null ||
        result.data?.access_token === undefined ||
        !result.data?.user_id
      ) {
        return { success: false, tokenNotFound: true };
      }
      localStorage.setItem("access_token", result.data?.access_token);
      localStorage.setItem("user_id", result.data.user_id);
      return { success: true };
    } else {
      return { success: false, invalidOtp: true };
    }
  } catch (err) {
    return { success: false };
  }
};

export const emailLogin = async (payload) => {
  try {
    const result = await postUrl(
      apiUrls.emailLogin,
      { email: payload.email, password: payload.password },
      false
    );

    if (result.status === 200) {
      if (
        result.data?.access === null ||
        result.data?.access === undefined ||
        !result.data?.user_id
      ) {
        return { success: false, tokenNotFound: true };
      }
      localStorage.clear();

      localStorage.setItem("access_token", result.data?.access);
      localStorage.setItem("user_id", result.data.user_id);

      return {
        success: true,
        message: "Login successful",
      };
    } else if (result.status === 401) {
      return {
        success: false,
        message: "Invalid credentials", // Invalid credentials
      };
    } else if (result.status === 404) {
      return {
        success: false,
        notFound: true, // Handle 404 error
      };
    }
    return { success: false, message: "An error occurred during login" };
  } catch (err) {
    return { success: false, message: "An error occurred during login" };
  }
};

export const createOrganization = async (payload, isGoogleSignup) => {
  let dataTosSend = {
    org_name: payload.bizName,
    biz_type: payload.bizType,
    mobile: payload.mobile,
  };

  if (isGoogleSignup) {
    dataTosSend = {
      org_name: payload.bizName,
      biz_type: payload.bizType,
      email: payload.email,
    };
  }

  try {
    const result = await postUrl(apiUrls.addOrganization, dataTosSend, false);
    if (isGoogleSignup && result.status === 201 && result.data.access_token) {
      localStorage.setItem("access_token", result.data?.access_token);
      localStorage.setItem("user_id", result.data?.user_id);
      localStorage.setItem("email", result.data?.email);
      navigate("/home");
    } else if (result.status === 201) {
      return { success: true, sentOtp: true };
    } else {
      return { success: false };
    }
  } catch (err) {
    return { success: false };
  }
};

export const attemptEmailSignup = async (payload) => {
  const dataToSend = {
    email: payload.email,
    password: payload.password,
    org_name: payload.bizName,
    biz_type: payload.bizType,
  };
  try {
    const response = await postUrl(
      apiUrls.emailPasswordSignup,
      dataToSend,
      false
    );
    if (response.status === 201 && response.success) {
      return { success: true, ...response };
    } else if (response.status === 400) {
      return {
        success: false,
        error: response.data?.email[0],
        emailAlreadyExistsError:
          response.data?.email[0] === messages.USER_EXISTS ? true : false,
        ...response,
      };
    }
    return { success: false, error: "Something went wrong!" };
  } catch (err) {
    console.log(err);
  }
};

export const getUserCountry = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_GEO_JS_ENDPOINT);
    return response.data?.country;
  } catch (err) {
    console.log(
      "Error occured while getting country code! Falling back to india!",
      err
    );
    return IN_SHORT_NAME;
  }
};

export const attemptPasswordReset = async (payload) => {
  const dataToSend = {
    email: payload.email,
  };

  try {
    const response = await postUrl(
      apiUrls.emailPasswordReset,
      dataToSend,
      false
    );

    if (response.status === 200 && response.success) {
      return { success: true };
    } else if (response.status === 404) {
      return {
        success: false,
        error: response.data?.email,
        emailNotFoundError: true,
      };
    }
    return { success: false, error: "Something went wrong!" };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      error: "An error occurred while processing your request.",
    };
  }
};
