import { useEffect, useState } from "react";
import "./index.css";
import { loginModalConstant, basicLoginModalConstant } from "./Login.constants";
// import { basicLoginModalConstant } from "./BasicLogin.constants";
import { validateForm } from "../../../../utils/validators";
import {
  attemptLogin,
  verifyOtp,
  emailLogin,
  attemptPasswordReset,
} from "../Auth.actions";
import { otpModal } from "./Login.constants";
import { useNavigate } from "react-router-dom";
import { IN_SHORT_NAME } from "../../../../common/Constants";
import { INPUT_TYPES } from "../../../../components/FormComponent/FormConstants";
import { useSearchParams } from "react-router-dom";
import { identifiers } from "../../../../utils/identifiers";
import { routes } from "../../../../common/routeUrls";

export const useLogin = (country) => {
  const [loginForm, setLoginForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [step, setStep] = useState(1);
  const [showResend, setShowResend] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [counter, setCounter] = useState(30);
  const [isForgotPassModalOpen, setForgotPassModalOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(
    country === IN_SHORT_NAME ? loginModalConstant : basicLoginModalConstant
  );
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);

  useEffect(() => {
    if (
      params.get("login") !== null &&
      params.get("login") !== undefined &&
      params.get("login") !== ""
    ) {
      setIsFirstTimeUser(true);
    }
  }, [params]);

  useEffect(() => {
    setLoginModal((prev) => {
      if (country === IN_SHORT_NAME) {
        return loginModalConstant;
      } else {
        let basicEmailModel = basicLoginModalConstant.map((input) => {
          if (input.type === INPUT_TYPES.PASSWORD) {
            return { ...input, onClickIcon: handleToggleShowPassword };
          }
          return input;
        });
        return basicEmailModel;
      }
    });
  }, [country]);

  useEffect(() => {
    let timerId = undefined;
    if (showTimer && counter) {
      timerId = setTimeout(() => {
        setCounter((prev) => prev - 1);
      }, [1000]);
    }
    if (counter === 0) {
      setShowTimer(false);
      setCounter(30);
      setShowResend(true);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [showTimer, showResend, counter]);

  const handleForm = (formName, value, fieldName) => {
    setLoginForm((prevForm) => {
      return {
        ...prevForm,
        [formName]: {
          ...prevForm[formName],
          [fieldName]: value,
        },
      };
    });
  };
  const handleToggleShowPassword = () => {
    setLoginModal((prev) => {
      if (country !== IN_SHORT_NAME) {
        let passwordField = prev[1];
        passwordField.type =
          passwordField.type === INPUT_TYPES.PASSWORD
            ? INPUT_TYPES.TEXT_INPUT
            : INPUT_TYPES.PASSWORD;
        prev[1] = passwordField;
        return [...prev];
      }
    });
  };

  const sendOtp = async (e, resend = false) => {
    if (e) {
      e.preventDefault();
    }
    setMessage({});
    if (resend) {
      setLoginModal((prev) => [{ ...prev[0] }]);
    }
    const { newForm, isValid } = validateForm(
      !resend ? loginModal : [{ ...loginModal[0] }],
      loginForm.loginForm
    );
    setLoginForm({ loginForm: { ...newForm } });
    if (!isValid && !resend) {
      return;
    }
    if (!resend) setLoading(true);
    const result = await attemptLogin(loginForm.loginForm);
    if (result.success) {
      setShowTimer(true);
      setStep(2);
      setLoginModal((prev) => {
        let mobileModal = { ...prev[0] };
        mobileModal.disabled = true;
        return [{ ...mobileModal }, { ...otpModal }];
      });
    } else if (result.orgNotFound) {
      setLoginForm({
        loginForm: {
          ...newForm,
          mobileError: "This account does not exists. Sign up",
        },
      });
    } else if (result.maxLimitReached) {
      setMessage({
        message: "You have reached maximum limit of OTP requests!",
        type: "error",
      });
    } else {
      setMessage({
        message: "Something went wrong! Try again later.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const verify = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const { newForm, isValid } = validateForm(loginModal, loginForm.loginForm);
    setLoginForm({ loginForm: { ...newForm } });
    if (!isValid) {
      return;
    }
    setLoading(true);
    const result = await verifyOtp(loginForm.loginForm);
    if (result.success) {
      navigate("/home");
    } else if (result.invalidOtp) {
      setLoginForm({
        loginForm: {
          ...newForm,
          otpError: "Invalid OTP",
        },
      });
    } else {
      setMessage({
        message: "Unable to verify. Try again later!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    if (country === IN_SHORT_NAME) {
      if (step === 1) {
        sendOtp(e);
      } else {
        verify(e);
      }
    } else {
      handleLogin(e);
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    const { newForm, isValid } = validateForm(loginModal, loginForm.loginForm);
    setLoginForm({ loginForm: { ...newForm } });

    if (!isValid) {
      return;
    }

    const loginData = {
      email: loginForm.loginForm.email,
      password: loginForm.loginForm.password,
    };
    setLoading(true);

    const result = await emailLogin(loginData);

    if (result.success) {
      if (isFirstTimeUser) {
        localStorage.setItem(identifiers.is_new_user, true);
        return navigate(routes.qrCodes);
      }
      navigate("/home");
    } else if (result.notFound) {
      setMessage({
        message: "An Error occurred during login",
        type: "error",
      });
    } else {
      setMessage({
        message: "Invalid Credentials",
        type: "error",
      });
    }
    setLoading(false);
  };

  return {
    loginForm,
    handleSubmit,
    loading,
    message,
    handleForm,
    loginModal,
    step,
    counter,
    showResend,
    showTimer,
    sendOtp,
    setShowResend,
    setCounter,
    setShowTimer,
    handleToggleShowPassword,
    handleLogin,
  };
};
