import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const PublicRoute = ({ children = null }) => {
  const value = localStorage.getItem("access_token");
  const isAuthenticated = value !== null && value !== undefined;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }
  return children;
};

PublicRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PublicRoute;
