import React, { useEffect, useRef } from "react";
import { useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "./index.css";
import moment from "moment";
import PropTypes from 'prop-types'

const DateInput = ({ styleClass, onChange, value, inputStyleClass, fieldName, validations, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCalendarToggle = (e) => {
    setIsOpen(true);
    e.stopPropagation();
  };

  const handleOnDateChange = (range) => {
    if (range[0] && range[1]) {
      setIsOpen(false);
      onChange(fieldName, range)
    }
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".app-datepicker-container")) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const validateValue = () => {
    let dateRange = value
    if (Array.isArray(dateRange) && dateRange.length === 2) {
      if (moment(dateRange[0]).isValid() && moment(dateRange[1]).isValid()) {
        return `${moment(dateRange[0]).format("DD/MM/YYYY")} - ${moment(dateRange[1]).format("DD/MM/YYYY")}`;
      }
    }
    return "";
  };

  return (
    <div className={`app-datepicker-container col-12 cursor-pointer`}>
      <div>
        {isOpen && (
          <DateRangePicker
            value={value}
            onChange={handleOnDateChange}
            isOpen={true}
            className={"app-date-picker"}
          />
        )}
      </div>
      <input
        type="text"
        value={validateValue()}
        placeholder={placeholder || "Date range"}
        readOnly
        onClick={handleCalendarToggle}
        className={`feedback-app-date-picker col-12 ${inputStyleClass}`}
      />
    </div>
  );
};

DateInput.propTypes = {
  styleClass: PropTypes.string,
  inputStyleClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  validations: PropTypes.array,
  value: PropTypes.array
}

export default DateInput;
