import React from "react";
import "./index.css";
import { useRef } from "react";
import StringUtils from "../../utils/StringUtils";
import { Link } from "react-router-dom";
import moment from "moment";
import { MdOutlineAddBusiness } from "react-icons/md";
import { MdCalendarMonth } from "react-icons/md";
import { FaChartSimple } from "react-icons/fa6";
import { HiStar } from "react-icons/hi";
import { Divider } from "antd";
import posthog from "posthog-js"; //

function QrCodes({
  bizType = "Restaurant",
  createdOn = "",
  totalScans = "",
  qrType = "",
  qrImageLink = "",
  qrDownloadLink = "",
  orgName = "",
}) {
  const qrCodesRef = useRef(null);
  const handleError = () => {
    if (qrCodesRef !== undefined && qrCodesRef !== null) {
      qrCodesRef.current.src = "https://placehold.co/600x400?text=Loading...";
    }
  };

  const getDateFormat = (date) => {
    if (moment(date).isValid()) {
      return moment(date).format("DD MMM YYYY");
    }
  };

  const handleDownloadClick = () => {
    // Track download event with PostHog
    posthog.capture("qr_code_download", {
      qrType,
      bizType,
      orgName,
      totalScans,
      timestamp: new Date().toISOString(),
    });
    console.log("QR Code download event tracked");
  };

  return (
    <>
      <script>
        {`gtag('event', 'conversion', {'send_to': 'AW-11033882276/KAVFCKm_-M4ZEKTdro0p','value': 1.0, 'currency': 'INR' });`}
      </script>
      <div className="qr-codes-card">
        <div className="qrcodes">
          <div style={{ flex: 1 }}>
            <p className="card-title">{orgName} QR codes</p>
            <div className="qrcodes-sub-deatils">
              <div className="qr-details-container">
                <div className="table-container">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span>
                            <MdOutlineAddBusiness
                              className="fdb-icons"
                              size={20}
                            />
                          </span>{" "}
                          Business Type :{" "}
                        </td>
                        <td className="qr-sideheader">
                          {StringUtils.capitalize(bizType)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            <MdCalendarMonth className="fdb-icons biz-icon" />
                          </span>
                          {/* {" "} */}
                          QR created on :{" "}
                        </td>
                        <td className="qr-sideheader">
                          {getDateFormat(createdOn)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-container">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span>
                            <FaChartSimple className="fdb-icons" />
                          </span>
                          Reviews :{" "}
                        </td>
                        <td className="qr-sideheader">{totalScans}</td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            <HiStar className="fdb-icons" />
                          </span>
                          QR type :{" "}
                        </td>
                        <td className="qr-sideheader">{qrType}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="table-container">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <a
                            className="btn-link"
                            href={qrDownloadLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleDownloadClick}
                          >
                            Download QRcode
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="qrimage-container">
            <img
              src={qrImageLink}
              onError={handleError}
              ref={qrCodesRef}
              className="qrcode"
            />
            <div class="qr-code-overlay"></div>
          </div>
        </div>
        <div className="sm-qrcodes">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <p className="sm-qr-heading">{orgName} QR Codes</p>
            <button className="date-btn">{getDateFormat(createdOn)}</button>
          </div>
          <Divider />
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            <div>
              <p className="sm-qr-details">Business Type</p>
              <p className="sm-qr-details value">
                {StringUtils.capitalize(bizType)}
              </p>
            </div>
            <Divider type="vertical" className="v-devider" />
            <div>
              <p className="sm-qr-details">Total Scans</p>
              <p className="sm-qr-details value">{totalScans}</p>
            </div>
            <Divider type="vertical" className="v-devider" />
            <div>
              <p className="sm-qr-details">QR Type</p>
              <p className="sm-qr-details value">{qrType}</p>
            </div>
            <Divider />
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <div className="qrimage-container">
              <img
                src={qrImageLink}
                onError={handleError}
                ref={qrCodesRef}
                className="qrcode"
              />
              <div class="qr-code-overlay"></div>
            </div>
            <div>
              <div>
                <a
                  className="btn-link"
                  href={qrDownloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download QRcode
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QrCodes;
