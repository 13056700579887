import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function ResponseMessage({ message }) {
  if (message.message === "") {
    return null;
  }
  return (
    <div
      className={classNames("response-message-container p-2", {
        success: message.type === "success",
        error: message.type === "error",
      })}
    >
      {message.message}
    </div>
  );
}

ResponseMessage.propTypes = {
  message: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default ResponseMessage;
