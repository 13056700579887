export const scrollToTop = () => {
  const scrollAnimation = () => {
    window.scrollTo(0, 0);
  };

  scrollAnimation();
};

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

function toCamelCase(str) {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
}

export function convertToCamelCase(value) {
  if(!value) return value
  if (
    value === Object(value) &&
    !Array.isArray(value) &&
    typeof value !== "function"
  ) {
    const result = {};
    Object.keys(value).forEach((eachValue) => {
      result[toCamelCase(eachValue)] = convertToCamelCase(value[eachValue]);
    });
    return result;
  } else if (Array.isArray(value)) {
    return value.map((i) => {
      return convertToCamelCase(i);
    });
  }

  return value;
}


export const cs = (value, valueToCompare, className) => {  // useless
  // Assume cs like conditional styling
  // to avoid writing terinary opertor thinking of using this
  if (value === valueToCompare) {
    return className;
  } else {
    return "";
  }
};