import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { routes } from "../../../common/routeUrls";

function MobileHeader({ username = "Anon", handleToogle }) {
  return (
    <div className="mobile-header">
      <GiHamburgerMenu size={25} onClick={handleToogle} />
      <Link to={routes.settings} style={{ padding: 0, margin: 0 }}>
        <img
          className={"mobile-avatar cursor-pointer"}
          src={`https://ui-avatars.com/api/?name=${username}&background=6fc6f2f7&bold=true&color=fff&rounded=true&size=40`}
        />
      </Link>
    </div>
  );
}

export default MobileHeader;
