import React, { Component } from "react";
import ErrorPage from "./ErrorPage";

export class ErrorBoundary extends Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <ErrorPage />
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
