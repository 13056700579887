import { setLoading } from "./loader.actions";
import reducer from "./loader.reducer";
import createStore from "../store";

const initialState = {
  loading: false,
};

export const { Context: LoaderContext, Provider: LoaderProvider } = createStore(
  reducer,
  { setLoading },
  initialState,
);
