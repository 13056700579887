import React from "react";
import AppLogo from "../AppIcons/AppLogo";

function Logo() {
  return (
    <div className="logo-wrapper me-3">
      <AppLogo />
    </div>
  );
}

export default Logo;
