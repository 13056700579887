import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { debounce } from "../../utils/debounce";
import "./index.css";
import { CiSearch } from "react-icons/ci";

const VERSION_KEY = process.env.REACT_APP_CURRENT_VERSION;

function Search() {
  const [search, setSearch] = useState("");

  const performSearch = () => {
    // console.log("Perform search now!");
  };

  const debounceSearch = useCallback(debounce(performSearch, 800), []);

  useEffect(() => {
    if (search) {
      debounceSearch();
    }
  }, [search]);

  const handleOnChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      {VERSION_KEY !== "v1" && (
        <div className="search-container">
          <CiSearch color="#202224" style={{ marginLeft: 5 }} />
          <input
            className="search-input"
            onChange={handleOnChange}
            placeholder="Search"
          />
        </div>
      )}
    </div>
  );
}

Search.propTypes = {
  search: PropTypes.string,
};

export default Search;
