export const validateMobileNumber = (mobileNumber) => {
  const indianPhoneNumberRegex = /^(0|\+?91)?[26789]\d{9}$/;
  let valid = indianPhoneNumberRegex.test(mobileNumber);
  return {
    valid: valid,
    message: valid ? "" : "Please enter a valid mobile number",
  };
};

export const validateEmail = (email) => {
  let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return {
    valid: regex.test(email),
    message: regex.test(email) ? "" : "Invalid email address",
  };
};

export const shouldHaveMinLenght = (lenght) => {
  return (value) => {
    return {
      valid: value.length >= lenght,
      message:
        value.length >= lenght ? "" : `Minimum length should be ${lenght}`,
    };
  };
};

export const validateURL = (url) => {
  var regex = /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6})([\/\w \.-]*)*\/?(\?.*)?(#.*)?$/;
  if(!url) return {valid: true, message: ""}  
  return {valid: regex.test(url), message: regex.test(url) ? "": "Invalid URL"};
}

export const validatePassword = (password = "") => {
  let message = `Please add necessary characters to create password.`;
  let failed = false;
  let failedValidations = [];
  const minLength = 8;
  const hasUpperCase = /[A-Z]/;
  const hasLowerCase = /[a-z]/;
  const hasNumber = /[0-9]/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  if (password.length < minLength) {
    failed = true;
    failedValidations.push("length");
  }
  if (!hasLowerCase.test(password)) {
    failed = true;
    failedValidations.push("lower");
  }
  if (!hasUpperCase.test(password)) {
    failed = true;
    failedValidations.push("upper");
  }
  if (!hasNumber.test(password)) {
    failed = true;
    failedValidations.push("number");
  }
  if (!hasSpecialChar.test(password)) {
    failed = true;
    failedValidations.push("special");
  }
  if (failed) {
    return { valid: false, message, failedValidations };
  }
  return { valid: true };
};

export const validateSimplePassword = (password) => {
  if (!password || password.length < 6) {
    return { valid: false, message: "Password should have 6 characters" };
  }
  return { valid: true };
};

export const validateForm = (model = [], formValue = {}) => {
  let newForm = { ...formValue };
  let isFormValid = true;
  model.forEach((field) => {
    let isFieldValid = true;
    if (field.required) {
      if (!formValue[field.fieldName]) {
        isFormValid = false;
        isFieldValid = false;
        newForm[`${field.fieldName}Error`] = "This field is required";
        return;
      }
    }
    if (field?.validators) {
      field.validators.forEach((validator) => {
        const result = validator(formValue[field.fieldName]);
        if (!result.valid) {
          isFormValid = false;
          isFieldValid = false;
          newForm[`${field.fieldName}Error`] = result.message;
        }
      });
    }
    if (isFieldValid) {
      newForm[`${field.fieldName}Error`] = "";
    }
  });
  return { newForm: { ...newForm }, isValid: isFormValid };
};
