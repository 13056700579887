import React, { useEffect, useRef, useState } from "react";
import FormComponent from "../../components/FormComponent/FormComponent";
import { accountInfoModel } from "./AccountInfo.Constants";
import styles from "./index.module.css";
import "../../components/FormComponent/index.css";
import { useDispatch, useSelector } from "react-redux";
import { convertToCamelCase } from "../../utils/utilities";
import { updateUserDetails } from "./AccountInfo.actions";
import { fetchUser } from "../../main/slices/User/userSlice";
import { identifiers } from "../../utils/identifiers";
import { validateForm } from "../../utils/validators";
import StringUtils from "../../utils/StringUtils";
import withUser from "../../hoc/withUser";

function AccountInfo(props) {
  const [accountForm, setAccountForm] = useState({});
  const [loading, setLoading] = useState(false);
  const user = convertToCamelCase(props.user);
  const dispatch = useDispatch();
  const username = user?.user?.organizationDetails?.[0].orgName;
  useEffect(() => {
    const accountType = user?.user?.organizationDetails?.[0]?.bizType;
    const formattedAccountType = accountType
      ? StringUtils.capitalize(accountType)
      : "";
    if (user) {
      setAccountForm({
        accountInformation: {
          name: user?.user?.organizationDetails?.[0].orgName || "",
          accountType: formattedAccountType,
          email: user?.user?.email || "",
          mobile: user?.user?.mobile || "",
          bizUrl:
            user?.user?.organizationDetails?.[0].publicReviewLinks?.Google ||
            "",
        },
      });
    }
  }, [user?.user?.id]);

  const handleOnChange = (formName, value, fieldName, fullValue) => {
    setAccountForm((prevForm) => {
      return {
        ...prevForm,
        [formName]: {
          ...prevForm[formName],
          [fieldName]: value,
        },
      };
    });
  };

  const handleSubmit = async () => {
    const { newForm: validatedForm, isValid } = validateForm(
      accountInfoModel,
      accountForm.accountInformation
    );
    setAccountForm({
      accountInformation: validatedForm,
    });
    if (!isValid) {
      return;
    }
    setLoading(true);
    let org_id = user?.user?.organizationDetails?.[0]?.id;
    await updateUserDetails(validatedForm, org_id);
    const userId = localStorage.getItem(identifiers.userId);
    await dispatch(fetchUser(userId));
    setLoading(false);
  };

  return (
    <>
      <p className="qr-codes-heading pb-2">Profile</p>
      <div
        className={`${styles["account-info-container"]} p-md-4 ps-md-5 pe-md-5 p-ms-0`}
      >
        <img
          className={styles["app-avatar"]}
          src={`https://ui-avatars.com/api/?name=${username}&background=6fc6f2f7&bold=true&color=fff&rounded=true&size=90`}
        />
        <FormComponent
          formName="accountInformation"
          onChange={handleOnChange}
          model={accountInfoModel}
          styleClass={"account-container"}
          showSubmit={true}
          formValue={accountForm.accountInformation}
          onSubmit={handleSubmit}
          loading={loading}
        />
        <div className="mb-4"></div>
        {/* <ButtonMain text={"Save"} additionalClass={`${styles['acc-save-btn']}`}  /> */}
      </div>
    </>
  );
}

const AccountInfoWithUser = withUser(AccountInfo);

export default AccountInfoWithUser;
