import React, { useEffect } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import classNames from "classnames";

function ErrorResponse({ text, type = "fail", ...props }) {
  return (
    <div
      className={classNames(
        "d-flex align-self-center align-items-center justify-content-center p-2 error-block mt-4 mb-2 col-md-9 col-sm-12 ms-4 me-4",
        { "d-none": !text, success: type === "success" }
      )}
      {...props}
    >
      <HiOutlineExclamationCircle
        size={40}
        color={type !== "success" ? "#EA0234" : "green"}
      />
      <p className="p-2">{text}</p>
      {/* <IoClose size={30} color='#EA0234' /> */}
    </div>
  );
}

export default ErrorResponse;
